import { persist } from 'mst-persist';
import { createContext, useContext } from 'react';
import config from '../../config';
import { RootStore } from '../model';
import { AsyncSessionStorage } from '../persist/asyncSessionStorage';

// create the root store
const rootStore = RootStore.create();
// create a context to allow store access via a custom hook
const storeContext = createContext(rootStore);
// tell localStorage to persist our store data
Promise.all([
  // persist interface store
  persist(`${config.localStateKey}_if`, rootStore.interfaceStore, {
    storage: AsyncSessionStorage,
  }),
  // persist claim store
  persist(`${config.localStateKey}_ds`, rootStore.documentStore, {
    storage: AsyncSessionStorage,
  }),
  // persist claim store
  persist(`${config.localStateKey}_cs`, rootStore.claimsStore, {
    storage: AsyncSessionStorage,
  }),
  // persist journey store
  persist(`${config.localStateKey}_js`, rootStore.journeyStore, {
    storage: AsyncSessionStorage,
  }),
]).then(() => {
  // all stores have been registered for persist and recovered
  // update interface hydrate attempt indicator
  rootStore.interfaceStore.setIsHydrateAttempted(true);
  // notify analytics about site initialisation
  // event('Application Initialised', {
  //   build: config.build,
  // });
});

// export our custom hook which will grant component access to our stores
export const useStores = (): typeof rootStore => useContext(storeContext);
