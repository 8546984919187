import React from 'react';
import { Box } from '@material-ui/core';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number): { 'aria-controls': string; id: string } {
  return {
    'aria-controls': `tabpanel-${index}`,
    id: `tab-${index}`,
  };
}
