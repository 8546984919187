import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Alert, Button, Checkbox, Snackbar, Toolbar, Tooltip, Typography } from '@esure-cloud/react-components';
import { IDocument } from '../../service/storageman';
import { useStores } from '../../service/state/store';
import { NotificationContent, ROUTE_TEMPLATE_STEP, SeverityType } from '../../service/constant';
import { useHistory } from 'react-router-dom';
import config from '../../service/config';
import { bytesToMb, formatedDate } from '../../service/util/utils';
import { useQuery } from '../../service/network';

interface Data {
  comments: string;
  documentAuthor: string;
  documentLink: string;
  documentMetadataId: string;
  documentName: string;
  documentSize: number;
  documentSource: string;
  documentType: string;
  fileFormat: string;
  uploadDateTime: string;
}

function createData(
  documentMetadataId: number,
  documentName: string,
  documentAuthor: string,
  documentType: string,
  documentSource: string,
  uploadDateTime: string,
  documentSize: number,
): Data {
  return {
    comments: '',
    documentAuthor,
    documentLink: '',
    documentMetadataId: documentMetadataId.toString(),
    documentName,
    documentSize,
    documentSource: documentSource,
    documentType: documentType,
    fileFormat: '',
    uploadDateTime: uploadDateTime,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

// eslint-disable-next-line
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric?: boolean;
}

const headCells: HeadCell[] = [
  { disablePadding: true, id: 'documentMetadataId', label: 'Document ID', numeric: false },
  { disablePadding: false, id: 'documentName', label: 'Document Name', numeric: false },
  { disablePadding: false, id: 'documentAuthor', label: 'Document Author', numeric: false },
  { disablePadding: false, id: 'fileFormat', label: 'File Format', numeric: false },
  { disablePadding: false, id: 'uploadDateTime', label: 'Upload Date Time', numeric: false },
  { disablePadding: false, id: 'documentSource', label: 'Document Source', numeric: false },
  { disablePadding: false, id: 'documentSize', label: 'Document Size', numeric: false },
  // { disablePadding: false, id: 'documentLink', label: 'Document Link' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.headCell}>
        <TableCell></TableCell>
        {headCells.map((headCell) =>
          headCell.id !== 'documentLink' ? (
            <TableCell
              key={headCell.id}
              align={'center'}
              className={classes.tableCell}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={false}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align={'center'}
              className={classes.tableCell}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
          ),
        )}
      </TableRow>
      <TableRow className={classes.headCell}>
        <TableCell className={classes.tableCellBg} padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) =>
          headCell.id !== 'documentLink' ? (
            <TableCell
              key={headCell.id}
              align={'center'}
              className={classes.tableCell}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <input className={classes.inputCell} />
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align={'center'}
              className={classes.tableCell}
              sortDirection={orderBy === headCell.id ? order : false}
            ></TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '5px',
    },
    buttonContainer: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            color: theme.palette.secondary.main,
          }
        : {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.text.primary,
          },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 auto',
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const history = useHistory();
  const query = useQuery();

  const claimNumber = query.get('claimNumber') ?? '';

  const routeChange = (route: string) => {
    history.push({ pathname: route, search: '?claimNumber=' + claimNumber });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="h6" component="div">
          {numSelected} documents selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" component="div">
          No documents selected
        </Typography>
      )}

      <div className={classes.buttonContainer}>
        <Button className={classes.button} onClick={() => routeChange(ROUTE_TEMPLATE_STEP.EDITOR)} variant="contained">
          Return to Editor
        </Button>
        {!numSelected ? (
          <>
            <Button className={classes.button} variant="contained" disabled>
              Attach Selected
            </Button>
          </>
        ) : (
          <>
            <Tooltip title="Attach the selected documents" placement="top-start">
              <Button
                className={classes.button}
                onClick={() => routeChange(ROUTE_TEMPLATE_STEP.EDITOR)}
                variant="contained"
              >
                Attach Selected
              </Button>
            </Tooltip>
          </>
        )}
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cellButton: {
      borderRadius: '5px',
      fontSize: '0.8rem',
      lineHeight: '1rem',
      width: 'max-content',
    },
    headCell: {
      backgroundColor: '#f5f5f5',
      color: theme.palette.common.black,
    },
    inputCell: {
      backgroundColor: '#f5f5f5',
      border: '1px solid #f0f0f0',
      borderRadius: '5px',
      height: '1.8rem',
      margin: 'auto',
      width: '100%',
    },
    paginationSection: {
      '& .MuiButtonBase-root .MuiListItem-root .MuiMenuItem-root .MuiTablePagination-menuItem .MuiMenuItem-gutters .MuiListItem-gutters .MuiListItem-button':
        {
          fontFamily: 'Nunito, sans-serif',
          fontKerning: 'normal',
          fontSize: '0.875rem',
          fontWeight: 600,
          letterSpacing: 0,
          lineHeight: '1.5rem',
          textRendering: 'optimizeLegibility',
          textTransform: 'none',
        },
      '& .MuiList-root.MuiMenu-list.MuiList-padding': {
        '& li': {
          fontFamily: 'Nunito, sans-serif',
          fontKerning: 'normal',
          fontSize: '0.875rem',
          fontWeight: 600,
          letterSpacing: 0,
          lineHeight: '1.5rem',
          textRendering: 'optimizeLegibility',
          textTransform: 'none',
        },
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: 'Nunito, sans-serif',
        fontKerning: 'normal',
        fontSize: '0.875rem',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '1.5rem',
        textRendering: 'optimizeLegibility',
        textTransform: 'none',
      },
      '& .MuiSvgIcon-root.MuiSelect-icon.MuiTablePagination-selectIcon': {
        fontFamily: 'Nunito, sans-serif',
        fontKerning: 'normal',
        fontSize: '0.875rem',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '1.5rem',
        textRendering: 'optimizeLegibility',
        textTransform: 'none',
      },
    },
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    root: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    tableCell: {
      fontSize: '0.8rem',
      lineHeight: '1rem',
      padding: '5px',
    },
    tableCellBg: {
      backgroundColor: '#f5f5f5',
    },
    tableRow: {
      padding: '5px',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export const AttachmentGrid: React.FC = () => {
  const { claimsStore } = useStores();
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('documentMetadataId');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rows, setRows] = useState<Data[]>([]);
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const [open, setOpen] = React.useState(false);
  const [severityAlert, setSeverityAlert] = React.useState<SeverityType>(SeverityType.Success);
  const [notificationContent, setNotificationContent] = React.useState<NotificationContent | string>(
    NotificationContent.DefaultError,
  );

  useEffect(() => {
    setDocuments(claimsStore.getIncomingDocuments());
    setRows(
      documents.map((document) =>
        createData(
          document.documentMetadataId,
          document.documentName,
          document.documentAuthor,
          document.documentType,
          document.documentSource,
          document.uploadDateTime,
          document.documentSize,
        ),
      ),
    );
    setSelected(claimsStore.selectedDocumentMetadataId);
  }, [claimsStore, documents]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let newSelecteds = rows.map((n) => n.documentMetadataId);

      const selectedDocArray = documents.filter((d) => newSelecteds.includes(d.documentMetadataId.toString()));

      const totalDocumentSize = selectedDocArray.reduce<number>((accumulator, obj) => {
        return accumulator + obj.documentSize;
      }, 0);

      if (totalDocumentSize > +config.attachFilesMaxSize) {
        handleNotification(SeverityType.Error, `File Size is bigger than ${bytesToMb(config.attachFilesMaxSize)} Mb`);
        newSelecteds = selected;
      } else {
        setSelected(newSelecteds);
        claimsStore.setDocumentMetadataId(newSelecteds);
        return;
      }
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event: React.MouseEvent<unknown>, documentId: string) => {
    const selectedIndex = selected.indexOf(documentId);

    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, documentId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    const selectedDocArray = documents.filter((d) => newSelected.includes(d.documentMetadataId.toString()));

    const totalDocumentSize = selectedDocArray.reduce<number>((accumulator, obj) => {
      return accumulator + obj.documentSize;
    }, 0);

    if (totalDocumentSize > +config.attachFilesMaxSize) {
      handleNotification(SeverityType.Error, `File Size is bigger than ${bytesToMb(config.attachFilesMaxSize)} Mb`);
      newSelected = selected;
    } else {
      setSelected(newSelected);
      claimsStore.setDocumentMetadataId(newSelected);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNotificationClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleNotification = (severity: SeverityType, content: NotificationContent | string) => {
    setOpen(true);
    setSeverityAlert(severity);
    setNotificationContent(content);
  };

  const isSelected = (documentId: string) => selected.indexOf(documentId) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleNotificationClose}>
          <Alert onClose={handleNotificationClose} severity={severityAlert}>
            {notificationContent}
          </Alert>
        </Snackbar>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.documentMetadataId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.documentMetadataId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.documentMetadataId}
                      className={classes.tableRow}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                      </TableCell>
                      <TableCell component="th" scope="row" className={classes.tableCell}>
                        {row.documentMetadataId}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {row.documentName}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {row.documentAuthor}{' '}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {row.documentType}{' '}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {formatedDate(row.uploadDateTime)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {row.documentSource}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {bytesToMb(row.documentSize)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          className={classes.paginationSection}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
