import { applySnapshot, types } from 'mobx-state-tree';
import { IDocument } from '../../storageman';
import { DEFAULT_EXTRACTED_METADATA, DEFAULT_SPECIFIC_DATA } from '../mock/stateMock';

export const DraftRequestDocument = types
  .model('DraftRequestDocuments', {
    claimNumber: types.optional(types.string, ''),
    comments: types.maybeNull(types.string),
    documentAuthor: types.maybeNull(types.string),
    documentDescription: types.maybeNull(types.string),
    documentExternalId: types.maybeNull(types.string),
    documentMetadataId: types.optional(types.number, 0),
    documentName: types.optional(types.string, ''),
    documentSize: types.optional(types.number, 0),
    documentSource: types.maybeNull(types.string),
    documentType: types.optional(types.string, ''),
    extractedMetadata: types.frozen(DEFAULT_EXTRACTED_METADATA),
    featureId: types.maybeNull(types.string),
    lastUpdateDateTime: types.optional(types.string, ''),
    mimeType: types.optional(types.string, ''),
    specificData: types.frozen(DEFAULT_SPECIFIC_DATA),
    uploadDateTime: types.optional(types.string, ''),
    version: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setDraftRequestDocuments(array?: IDocument) {
      applySnapshot(self, array);
    },
  }));
