import React from 'react';
import { Alert } from '@esure-cloud/react-components';
import { AlertTitle } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FileDownloadButton } from './FileDownloadButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootErrorWrap: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const DownloadBanner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.rootErrorWrap}>
      <Alert variant="outlined" severity="success">
        <AlertTitle>You can always download the file</AlertTitle>
        <p>
          Since Document Viewer is having issues at the moment, please download file and view it in your preferred app.
        </p>
        <FileDownloadButton />
      </Alert>
    </div>
  );
};
