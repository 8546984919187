import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert, Button, Grid, Paper, Tooltip } from '@esure-cloud/react-components';
import { useStores } from '../../service/state/store';
import { computeChecksumMd5 } from '../../service/util/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '8px',
      height: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    form: {
      flexGrow: 1,
      margin: '3rem 0 auto auto',
    },
    grid: {
      display: 'flex',
      width: '100%',
    },
    hidden: {
      display: 'none',
    },
    paper: {
      alignItems: 'center',
      backgroundColor: '#e0e0e0',
      color: theme.palette.text.secondary,
      display: 'flex',
      flexGrow: 1,
      padding: '12px',
    },
  }),
);

interface FileInputProps {
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
}

export const DocumentFileUploadOrUpdateForm: React.FC<FileInputProps> = ({ setFile, error, setError }) => {
  const { documentStore } = useStores();
  const classes = useStyles();

  const [name, setName] = React.useState(
    documentStore.getDocumentName() ? documentStore.getDocumentName() : 'No file selected',
  );

  const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      setError('');
      setFile(event.target.files[0]);
      setName(event.target.files[0].name);

      const file = event.target.files[0];

      const md5File = await computeChecksumMd5(file);

      documentStore.setMd5(md5File);
      documentStore.setDocumentName(event.target.files[0].name);
      documentStore.setDocumentSize(event.target.files[0].size);
      documentStore.setDocumentFormat(event.target.files[0].type);
    }
  };

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item className={classes.grid} xs={12}>
          <Paper className={classes.paper}>{name}</Paper>
          <input
            color="primary"
            type="file"
            onChange={handleFileInput}
            id="icon-button-file"
            className={classes.hidden}
          />
          <Tooltip title="Select a document from your files" placement="bottom">
            <label htmlFor="icon-button-file">
              <Button className={classes.button} variant="outlined" component="span">
                Browse
              </Button>
            </label>
          </Tooltip>
        </Grid>
      </Grid>

      {error && (
        <div className={classes.form}>
          <Alert severity="error"> {error} </Alert>
        </div>
      )}
    </form>
  );
};
