import { applySnapshot, Instance, types } from 'mobx-state-tree';

export interface IJourney {
  claimNumberType: string;
  page: string;
  showError: boolean;
  tabIndex: number;
}

export const JourneyStore = types
  .model('JourneyStore', {
    claimNumberType: types.optional(types.string, ''),
    currentDocumentMetadataId: types.optional(types.number, 0),
    page: types.optional(types.string, ''),
    showError: types.optional(types.boolean, false),
    tabIndex: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setClaimNumberType(val: string) {
      self.claimNumberType = val;
    },
    setCurrentDocumentMetadataId(val: number) {
      self.currentDocumentMetadataId = val;
    },
    setJourney(obj: IJourney) {
      applySnapshot(self, obj);
    },
    setPage(val: string) {
      self.page = val;
    },
    setShowError(val: boolean) {
      self.showError = val;
    },
    setTabIndex(val: number) {
      self.tabIndex = val;
    },
  }));

export type IJourneyStore = Instance<typeof JourneyStore>;
export default JourneyStore;
