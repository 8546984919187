import { IDocumentStore } from '../../service/state/model/document/documentStore';
import { IDocument } from '../../service/storageman';
import { IClaimsStore } from '../../service/state/model/claims/claimStore';

export const resetDocumentState = (documentStore: IDocumentStore): void => {
  documentStore.setDocumentMetadataId('');
  documentStore.setDocumentAuthor('');
  documentStore.setDocumentComments('');
  documentStore.setDocumentDescription('');
  documentStore.setDocumentFormat('');
  documentStore.setDocumentName('');
  documentStore.setDocumentSize(0);
  documentStore.setDocumentType('');
  documentStore.setFeatureId('');
  documentStore.setMd5('');
  documentStore.setPresignedURL('');
};

export const updateDocumentState = (documentStore: IDocumentStore, document: IDocument): void => {
  documentStore.setDocumentMetadataId(document.documentMetadataId.toString());
  documentStore.setDocumentAuthor(document.documentAuthor);
  documentStore.setDocumentComments(document.comments ?? '');
  documentStore.setDocumentDescription(document.documentDescription ?? '');
  documentStore.setDocumentName(document.documentName);
  documentStore.setDocumentSize(document.documentSize);
  documentStore.setDocumentType(document.documentType);
  documentStore.setFeatureId(document.featureId ?? '');
  documentStore.setVersion(document.version);
};

export const uploadDocumentState = (documentStore: IDocumentStore, claimStore: IClaimsStore): void => {
  documentStore.setDocumentAuthor(claimStore.getOktaEmail());
};
