import { Instance, types } from 'mobx-state-tree';
import { DraftResponseFull, DraftTemplateRequestFull, InterfaceDocGenSearch, PartyFull } from './interfaceDocGenSearch';

export const InterfaceStore = types
  .model('InterfaceStore', {
    docGenSearch: types.optional(InterfaceDocGenSearch, () => InterfaceDocGenSearch.create()),
    draftResponseFull: types.optional(DraftResponseFull, () => DraftResponseFull.create()),
    draftTemplateRequestFull: types.optional(DraftTemplateRequestFull, () => DraftTemplateRequestFull.create()),
    partyFull: types.optional(PartyFull, () => PartyFull.create()),
  })
  .volatile(() => ({
    isHydrateAttempted: false,
  }))
  .actions((self) => ({
    setIsHydrateAttempted(newValue: boolean) {
      self.isHydrateAttempted = newValue;
    },
  }));

export type IInterfaceStore = Instance<typeof InterfaceStore>;
