import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Alert, CircularProgress, Typography } from '@esure-cloud/react-components';
import { useStores } from '../../service/state/store';
import { useQuery } from '../../service/network';

const useStyles = makeStyles(() =>
  createStyles({
    confirmation: {
      margin: 'auto 0',
    },
    metadata: {
      margin: '2rem 2rem auto',
    },
    progressContainer: {
      textAlign: 'center',
    },
  }),
);

interface uploadConfirmationProps {
  loading: boolean;
}

export const DocumentUploadConfirmation: React.FC<uploadConfirmationProps> = ({ loading }) => {
  const classes = useStyles();
  const { documentStore } = useStores();

  const query = useQuery();
  const claimNumber: string = query.get('claimNumber') ?? '';

  const [documentMetadataId] = React.useState(documentStore.getDocumentMetadataId());
  const [description] = React.useState(documentStore.getDocumentDescription());
  const [name] = React.useState(documentStore.getDocumentName());
  const [type] = React.useState(documentStore.getDocumentType());
  const [featureId] = React.useState(documentStore.getFeatureId());
  const [author] = React.useState(documentStore.getDocumentAuthor());
  const [comments] = React.useState(documentStore.getDocumentComments());

  return (
    <div className={classes.confirmation}>
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress />
          <Typography gutterBottom>Uploading...</Typography>
        </div>
      ) : (
        <div className={classes.confirmation}>
          <Alert severity="success">
            The Document has been sucsessfuly added to the Claim Nr. # {claimNumber}
            <div className={classes.metadata}>
              <Typography variant="h3" gutterBottom>
                {' '}
                Document metadata:{' '}
              </Typography>
              <Typography gutterBottom> - Document Metadata ID: {documentMetadataId}</Typography>
              <Typography gutterBottom> - Document Name: {name}</Typography>
              <Typography gutterBottom> - Feature ID: {featureId}</Typography>
              <Typography gutterBottom> - Document Type: {type}</Typography>
              <Typography gutterBottom> - Document Author: {author}</Typography>
              <Typography gutterBottom> - Document Comment: {comments}</Typography>
              <Typography gutterBottom> - Description: {description} </Typography>
            </div>
          </Alert>
        </div>
      )}
    </div>
  );
};
