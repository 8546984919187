import LuxonUtils from '@date-io/luxon';
import { CssBaseline, MuiPickersUtilsProvider, MuiThemeProvider, themeEsure } from '@esure-cloud/react-components';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ErrorHandler } from './component/common';
import { i18nLoader } from './service/i18n';
import { expandRoutePath } from './service/routeConfig';
import { ViewRoot } from './view';
import './index.css';

(() => {
  const i18n = i18nLoader;

  // root render view for the high level react/utility/routing wrappers
  const RootRender: React.FC = () => (
    <StrictMode>
      <MuiThemeProvider theme={themeEsure}>
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <ErrorHandler>
              <BrowserRouter basename={expandRoutePath()}>
                <ViewRoot />
              </BrowserRouter>
            </ErrorHandler>
          </MuiPickersUtilsProvider>
        </I18nextProvider>
      </MuiThemeProvider>
    </StrictMode>
  );

  // boot react with initial mount to the dom
  render(<RootRender />, document.getElementById('root'));
})();
