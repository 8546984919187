import { Alert } from '@esure-cloud/react-components';
import React from 'react';
import { AlertTitle } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface ErrorProps {
  message: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootErrorWrap: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const ErrorBanner: React.FC<ErrorProps> = (props: ErrorProps) => {
  const classes = useStyles();
  return (
    <div className={classes.rootErrorWrap}>
      <Alert variant="outlined" severity="error">
        <AlertTitle>Uh oh, Document Viewer is unavailable!</AlertTitle>
        <p>There was an unexpected problem:</p>
        <pre>{props.message}</pre>
      </Alert>
    </div>
  );
};
