import { Step, StepButton, StepLabel, Stepper } from '@esure-cloud/react-components';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../service/state/store';

export const DocGenBreadcrumbRow: React.FC = observer(() => {
  const { t } = useTranslation('docGen');
  const { interfaceStore } = useStores();
  const history = useHistory();

  const breadcrumbSteps: string[] = t('breadcrumbStepsDoc', { returnObjects: true });

  const stepClickHandler = useCallback(
    (i) =>
      i < interfaceStore.docGenSearch.activeStepIndex &&
      history.push(interfaceStore.docGenSearch.transformIndexToPath(i)),
    [history, interfaceStore.docGenSearch],
  );

  return (
    <Stepper activeStep={interfaceStore.docGenSearch.activeStepIndex} alternativeLabel id="breadcrumb-stepper">
      {breadcrumbSteps.map((stepLabel, i) => (
        <Step id={`breadcrumb-step-${i}`} key={`step_${i}`}>
          <StepButton onClick={stepClickHandler}>
            <StepLabel>{stepLabel}</StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
});
