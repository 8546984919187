import config from './config';

export const eventIdentify = (userId: string): void => {
  if (userId) {
    analytics.identify(userId);
  }
};

export const event = (name: string, data?: null | Record<string, unknown>): void => {
  analytics.track(name, data ?? undefined);
};

export const eventError = (type?: string, thrown?: unknown): void => {
  const error = thrown instanceof Error ? thrown : null;
  analytics.track('Error Encountered', {
    error: {
      message: error?.message,
      name: error?.name,
    },
    type,
  });

  if (!config.isProduction) {
    // eslint-disable-next-line no-console
    console.error(type, error);
  }
};

export const eventPage = (name?: string, data?: null | Record<string, unknown>): void => {
  analytics.page(name, data ?? undefined);
};
