import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, MenuItem, TextField } from '@esure-cloud/react-components';
import { useStores } from '../../service/state/store';
import { CLAIMTYPE } from '../common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '8px',
      height: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    grid: {
      width: '100%',
    },
    inputField: {
      margin: '0 5px',
      width: 'calc(100% - 10px)',
    },
    root: {
      flexGrow: 1,
      marginTop: '1rem',
    },
  }),
);

export const motorTypes = [
  'Credit Hire',
  'Engineers Report',
  'General',
  'Invoices',
  'Judgement',
  'Liability',
  'Medical Records',
  'Notice Of Issue',
  'Vehicle Damage Documents',
];

export const homeTypes = ['General', 'Supplier'];

export const allOthersTypes = [
  'Credit Hire',
  'Engineers Report',
  'General',
  'Invoices',
  'Judgement',
  'Liability',
  'Medical Records',
  'Notice Of Issue',
  'Vehicle Damage Documents',
  'Supplier',
];

interface DocumentMetadataUploadOrUpdateFormProps {
  docTypeValid: React.Dispatch<React.SetStateAction<boolean>>;
  nameValid: React.Dispatch<React.SetStateAction<boolean>>;
  setFormValid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DocumentMetadataUploadOrUpdateForm: React.FC<DocumentMetadataUploadOrUpdateFormProps> = ({
  docTypeValid,
  nameValid,
  setFormValid,
}) => {
  const { documentStore, journeyStore } = useStores();
  const classes = useStyles();
  const [description, setDescription] = React.useState(documentStore.getDocumentDescription());
  const [name, setName] = React.useState(documentStore.getDocumentName());
  const [type, setType] = React.useState(documentStore.getDocumentType());
  const [featureId, setFeatureId] = React.useState(documentStore.getFeatureId());
  const [author, setAuthor] = React.useState(documentStore.getDocumentAuthor());
  const [comments, setComments] = React.useState(documentStore.getDocumentComments());

  useEffect(() => {
    documentStore.setDocumentName(name);
    documentStore.setDocumentDescription(description);
    documentStore.setDocumentType(type);
    documentStore.setDocumentComments(comments);
    documentStore.setDocumentAuthor(author);
    documentStore.setFeatureId(featureId ?? '');
  }, [description, name, type, featureId, author, comments, documentStore]);

  useEffect(() => {
    //Form validation check if name and type are not empty
    name.length > 0 && type.length > 0 ? setFormValid(true) : setFormValid(false);
    name.length > 0 ? nameValid(true) : nameValid(false);
    type.length > 0 ? docTypeValid(true) : docTypeValid(false);
  });

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container>
        <Grid item className={classes.grid} xs={12} md={6}>
          <TextField
            label="Document Name"
            id="outlined-multiline-flexible"
            value={name}
            onChange={(event) => setName(event.target.value)}
            className={classes.inputField}
            variant="outlined"
            required={true}
          />
        </Grid>

        <Grid item className={classes.grid} xs={12} md={6}>
          <TextField
            id="outlined-select-document-type"
            select
            className={classes.inputField}
            label="Document Type"
            value={type}
            onChange={(event) => setType(event.target.value)}
            helperText="Please select the document type"
            variant="outlined"
            required={true}
          >
            {journeyStore.claimNumberType === CLAIMTYPE.MOTOR &&
              motorTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            {journeyStore.claimNumberType === CLAIMTYPE.HOME &&
              homeTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            {journeyStore.claimNumberType === CLAIMTYPE.UNKNOWN &&
              allOthersTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid item className={classes.grid} xs={12}>
          <TextField
            label="Description"
            id="outlined-multiline-flexible"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            className={classes.inputField}
            variant="outlined"
            multiline
            rows={3}
            helperText="Please type a short desctiption. (optional)"
          />
        </Grid>

        <Grid item className={classes.grid} xs={12} md={6}>
          <TextField
            label="Feature ID"
            id="featureId"
            value={featureId}
            onChange={(event) => setFeatureId(event.target.value)}
            className={classes.inputField}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item className={classes.grid} xs={12} md={6}>
          <TextField
            disabled
            label="Document Author"
            id="author"
            value={author}
            onChange={(event) => setAuthor(event.target.value)}
            className={classes.inputField}
            variant="outlined"
          />
        </Grid>

        <Grid item className={classes.grid} xs={12}>
          <TextField
            label="Comments"
            id="outlined-multiline-flexible"
            value={comments}
            onChange={(event) => setComments(event.target.value)}
            className={classes.inputField}
            variant="outlined"
            multiline
            rows={2}
            helperText="Please type a short comment. (optional)"
          />
        </Grid>
      </Grid>
    </form>
  );
};
