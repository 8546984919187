import { observer } from 'mobx-react-lite';
import { Grid, makeStyles, Typography } from '@esure-cloud/react-components';
import { useStores } from '../../../service/state/store';

const useStyles = makeStyles(() => ({
  claimSummarySvg: {
    margin: '6rem auto 3rem',
    width: '55%',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
}));

export const Confirmation: React.FC = observer(() => {
  const classes = useStyles();
  const { interfaceStore } = useStores();
  interfaceStore.docGenSearch.setActiveStepIndex(2);

  return (
    <>
      <Grid className={classes.container} id="find">
        <img className={classes.claimSummarySvg} alt="claimSummary" src="./assets/images/claimSummary.svg" />
        <Typography variant="h3" component="h3" className={classes.textAlignCenter}>
          Your generation request has been successfully received and will be processed.
        </Typography>
      </Grid>
    </>
  );
});
