import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBar, Container, Paper, Theme, useMediaQuery } from '@esure-cloud/react-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ROUTE, ROUTE_TEMPLATE_STEP } from '../../service/constant';
import { Tab, Tabs } from '@material-ui/core';
import { useStores } from '../../service/state/store';
import styled from 'styled-components';

const HeaderWrap = styled.div`
  margin-bottom: 50px;
`;

const LogoNavWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

function a11yProps(index: number) {
  return {
    'aria-controls': `tabpanel-${index}`,
    id: `tab-${index}`,
  };
}

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export enum CLAIMTYPE {
  HOME = 'HOME',
  MOTOR = 'MOTOR',
  UNKNOWN = 'UNKNOWN',
}

export const Header: React.FC = observer(() => {
  const { t } = useTranslation('common');
  const { journeyStore } = useStores();
  const query = useQuery();
  const claimNumber = query.get('claimNumber') ?? '';
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    journeyStore.setTabIndex(newValue);
    if (claimNumber) {
      journeyStore.tabIndex === 1
        ? history.push({ pathname: ROUTE.DOCUMENTS, search: `?claimNumber=${claimNumber}` })
        : history.push({ pathname: ROUTE_TEMPLATE_STEP.SEARCH, search: `?claimNumber=${claimNumber}` });
    } else {
      journeyStore.tabIndex === 1
        ? history.push({ pathname: ROUTE.DOCUMENTS })
        : history.push({ pathname: ROUTE_TEMPLATE_STEP.SEARCH });
    }
  };

  function homeOrMotorClaimNumber(str: string) {
    if (/^[100]/.test(str)) return CLAIMTYPE.HOME;
    else if (/^[500]/.test(str)) return CLAIMTYPE.MOTOR;
    else return CLAIMTYPE.UNKNOWN;
  }

  useEffect(() => {
    if (claimNumber) {
      journeyStore.setClaimNumberType(homeOrMotorClaimNumber(claimNumber));
    }
  }, [journeyStore, claimNumber]);

  useEffect(() => {
    //Set index so that underline is accurate to current tabIndex - ensure direct load of incoming Url will display for Tab Panels
    history.location.pathname === '/template/search' ||
    history.location.pathname === '/template/confirmation' ||
    history.location.pathname === '/template/editor' ||
    history.location.pathname === '/attachment'
      ? journeyStore.setTabIndex(0)
      : journeyStore.setTabIndex(1);
  });

  return (
    <HeaderWrap>
      <AppBar color="inherit" position="fixed">
        <Container fixed maxWidth="xl">
          <LogoNavWrap>
            <>
              <img
                alt={t('header.brandImageAlt')}
                height={isSmallDevice ? 32 : 49}
                src="./assets/images/esureLogo.svg"
                width={isSmallDevice ? 63 : 94}
              />
            </>
            <Paper square>
              <Tabs value={journeyStore.tabIndex} onChange={handleChange}>
                <Tab label="Generate on Demand Documents" {...a11yProps(journeyStore.tabIndex)} />
                <Tab label="Documents view and search" {...a11yProps(journeyStore.tabIndex)} />
                {/*<Tab disabled={!!error} label="Upload documents" {...a11yProps(2)} />*/}
              </Tabs>
            </Paper>
          </LogoNavWrap>
        </Container>
      </AppBar>
    </HeaderWrap>
  );
});
