export enum Type {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum ReferenceType {
  CLAIM = 'CLAIM',
}

export enum Action {
  DELIVER = 'DELIVER',
  GENERATE = 'GENERATE',
}

export enum FEAction {
  ITERATE = 'ITERATE',
  PUSH = 'PUSH',
}

export enum Channel {
  EMAIL = 'EMAIL',
  LETTER = 'LETTER',
  SMS = 'SMS',
}

// https://github.com/esure-cloud/java-lib-docgen-swg/blob/develop/src/main/java/com/esure/docgen/swagger/model/request/GenerateDocumentRequest.java
//we are keeping claimNumber for backwards compatibility
//we are keeping type for backwards compatibility
export interface IDraftRequest {
  action: string;
  channel: string;
  document: (IDocumentPart | IFile)[];
  featureId?: number;
  name: string;
  recipient: IDocGenRecipient;
  referenceNumber: string;
  referenceType: string;
  sender: IDocGenCustomer;
  type: string;
}

export interface IDocGenCustomer {
  email: string;
  name: string;
}

export interface IDocumentPart {
  template: ITemplate;
}

export interface IFile {
  file: IFilePart;
}

export interface IFilePart {
  documentMetadataId: string;
}

export interface ITemplate {
  documentPartId: number;
  payload: string;
  projectId: number;
  subject: string | null;
  templatePaginationId: number;
  templateSelectorId: number;
}

export interface IDocGenRecipient {
  email: string;
  name: string;
  phoneNumber: string;
}
