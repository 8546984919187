import { Component } from 'react';
import { eventError } from '../../service/analytic';

interface ErrorHandlerState {
  hasError: boolean;
}

export class ErrorHandler extends Component<{ children: React.ReactNode }, ErrorHandlerState> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorHandlerState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    eventError('Unexpected Error Encountered', error);
    // error logging goes here
    // logErrorToService(error, info);
  }

  renderFallback(): React.ReactNode {
    return (
      <div>
        <div>Oops, the application reached an unexpected state.</div>
      </div>
    );
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return this.renderFallback();
    }

    return children;
  }
}
