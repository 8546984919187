import React, { useEffect, useMemo, useState } from 'react';
import config from '../../service/config';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { Button, Checkbox } from '@esure-cloud/react-components';
import { IDocument, storageManService } from '../../service/storageman';
import { useStores } from '../../service/state/store';
import { DocumentsTableToolbar } from './documentsTableToolbar';
import { createData, Data } from './documentsData';
import { getComparator, Order, stableSort } from './documentsSortingService';
import { Link } from 'react-router-dom';
import { allOthersTypes, homeTypes, motorTypes } from '../document/documentMetadataUploadOrUpdateForm';
import { CLAIMTYPE } from '../common';
import { formatedDate } from '../../service/util/utils';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Visibility from '@material-ui/icons/Visibility';
import { useQuery } from '../../service/network';
import { prizmDocSupportedFileExtensions } from '../../service/constant';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cellButton: {
      borderRadius: '5px',
      fontSize: '0.8rem',
      lineHeight: '1rem',
      width: 'max-content',
    },
    dateCell: {
      display: 'flex',
    },
    disabled: {
      '&:hover': {
        cursor: 'not-allowed',
      },
      background: '#ccc',
      opacity: '0.5',
    },
    headCell: {
      backgroundColor: '#f5f5f5',
      color: theme.palette.common.black,
    },
    inputCell: {
      backgroundColor: '#fbfbfb',
      border: '1px solid #f0f0f0',
      borderRadius: '5px',
      height: '1.8rem',
      margin: 'auto',
      padding: '0 5px',
      width: '100%',
    },
    label: {
      margin: 'auto',
    },
    link: {
      textDecoration: 'none',
    },
    paginationSection: {
      '& .MuiButtonBase-root .MuiListItem-root .MuiMenuItem-root .MuiTablePagination-menuItem .MuiMenuItem-gutters .MuiListItem-gutters .MuiListItem-button':
        {
          fontFamily: 'Nunito, sans-serif',
          fontKerning: 'normal',
          fontSize: '0.875rem',
          fontWeight: 600,
          letterSpacing: 0,
          lineHeight: '1.5rem',
          textRendering: 'optimizeLegibility',
          textTransform: 'none',
        },
      '& .MuiList-root.MuiMenu-list.MuiList-padding': {
        '& li': {
          fontFamily: 'Nunito, sans-serif',
          fontKerning: 'normal',
          fontSize: '0.875rem',
          fontWeight: 600,
          letterSpacing: 0,
          lineHeight: '1.5rem',
          textRendering: 'optimizeLegibility',
          textTransform: 'none',
        },
      },
      '& .MuiSelect-select.MuiSelect-select': {
        fontFamily: 'Nunito, sans-serif',
        fontKerning: 'normal',
        fontSize: '0.875rem',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '1.5rem',
        textRendering: 'optimizeLegibility',
        textTransform: 'none',
      },
      '& .MuiSvgIcon-root.MuiSelect-icon.MuiTablePagination-selectIcon': {
        fontFamily: 'Nunito, sans-serif',
        fontKerning: 'normal',
        fontSize: '0.875rem',
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '1.5rem',
        textRendering: 'optimizeLegibility',
        textTransform: 'none',
      },
    },
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    root: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    tableCell: {
      fontSize: '0.8rem',
      lineHeight: '1rem',
      padding: '5px',
    },
    tableCellBg: {
      backgroundColor: '#f5f5f5',
    },
    tableRow: {
      padding: '5px',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export const DocumentsGrid: React.FC = () => {
  const { claimsStore, documentStore, journeyStore } = useStores();
  const classes = useStyles();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('documentId');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [rows, setRows] = useState<Data[]>([]);
  const [disableRow, setDisableRow] = useState(false);
  const [resetGrid, setResetGrid] = useState(false);

  const query = useQuery();
  const claimNumber = query.get('claimNumber') ?? '';

  const headCells: HeadCell[] = [
    { disablePadding: false, id: 'documentName', label: 'Document Name', numeric: false },
    { disablePadding: false, id: 'featureId', label: 'Feature ID', numeric: false },
    { disablePadding: false, id: 'documentType', label: 'Document Type', numeric: false },
    { disablePadding: false, id: 'fileFormat', label: 'Document Format', numeric: false },
    { disablePadding: false, id: 'documentSource', label: 'Document Source', numeric: false },
    { disablePadding: false, id: 'documentAuthor', label: 'Document Author', numeric: false },
    { disablePadding: false, id: 'receivedOrSendingDate', label: 'Received / Sending Date', numeric: false },
    { disablePadding: false, id: 'documentLink', label: 'View Document' },
  ];

  const [filters, setFilters] = useState({
    documentAuthor: '',
    documentName: '',
    documentSource: '',
    documentType: 'All',
    documentUploadDateFrom: '',
    documentUploadDateTo: new Date().toISOString(),
    featureId: '',
    fileFormat: '',
  });

  const filteredRows = useMemo(
    () =>
      rows.filter(
        (document) =>
          document.documentAuthor.toLowerCase().includes(filters.documentAuthor.toLowerCase()) &&
          document.documentName.toLowerCase().includes(filters.documentName.toLowerCase()) &&
          document.documentSource.toLowerCase().includes(filters.documentSource.toLowerCase()) &&
          document.documentType.includes(filters.documentType === 'All' ? '' : filters.documentType) &&
          document.featureId.includes(filters.featureId) &&
          document.mimeType.toLowerCase().includes(filters.fileFormat.toLowerCase()) &&
          document.uploadDateTime > filters.documentUploadDateFrom &&
          document.uploadDateTime < filters.documentUploadDateTo,
      ),
    [rows, filters],
  );

  const filterBySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFilters = { ...filters, [name]: value };
    //Update filters with user input
    setFilters(newFilters);
  };

  useEffect(() => {
    //setDocuments from claimStore when component mounts
    setDocuments(claimsStore.getIncomingDocuments());

    //setRows from documents when component mounts
    setRows(
      documents.map((document) =>
        createData(
          document.documentAuthor ?? '',
          document.documentMetadataId.toLocaleString(),
          '',
          document.documentName ?? '',
          document.documentSource ?? '',
          document.documentType,
          document.featureId ?? '',
          document.mimeType,
          document.uploadDateTime,
          document.mimeType,
          document.uploadDateTime,
        ),
      ),
    );
  }, [claimsStore, documents]);

  const downloadFile = async (documentMetadataId: string) => {
    const documentResponce = await storageManService.retrieveDocument(documentMetadataId);
    const [first] = documentResponce.data.results;
    const {
      documentLocation,
      documentName,
      mimeType,
    }: { documentLocation: string; documentName: string; mimeType: string } = first;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.URL = window.URL || window.webkitURL;

    const xhr = new XMLHttpRequest();
    const a = document.createElement('a');

    xhr.open('GET', documentLocation, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const file = new Blob([xhr.response], { type: mimeType });
      a.href = window.URL.createObjectURL(file);
      a.download = documentName; //'someName.gif'; // Set to whatever file name you want
      a.click();
    };
    xhr.send();
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //Preserve for future use
  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.documentId);
  //     setSelected(newSelecteds);
  //     claimsStore.setDocumentMetadataId(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  //   claimsStore.setDocumentMetadataId([]);
  // };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    disableRow: boolean,
    documentName: string,
    documentId: string,
  ) => {
    const selectedIndex = selected.indexOf(documentId);
    let newSelected: string[] = [];

    //NEW CHECK FOR SINGLE FILE
    if (!disableRow && selectedIndex === -1) {
      newSelected = newSelected.concat(selected, documentId);
    } else if (disableRow && selectedIndex === -1) {
      return;
    }

    setSelected(newSelected);
    documentStore.setDocumentName(documentName);

    //PREVIOUS LOGIC FOR MULTI SELECT
    // else if (selectedIndex === 0) {
    //     //newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   //newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   //newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }

    claimsStore.setDocumentMetadataId(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (documentId: string) => selected.indexOf(documentId) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {
    if (selected.length > 0) {
      setDisableRow(true);
    } else {
      setDisableRow(false);
    }
  }, [selected]);

  useEffect(() => {
    if (resetGrid) {
      setSelected([]);
    }
  }, [resetGrid]);

  const [displayToDate, setDisplayToDate] = useState('');

  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const documentsFilteringWithAll = ['All'];

  const motorWithAll = documentsFilteringWithAll.concat(motorTypes);
  const homeWithAll = documentsFilteringWithAll.concat(homeTypes);
  const allOthersWithAll = documentsFilteringWithAll.concat(allOthersTypes);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <DocumentsTableToolbar resetGrid={setResetGrid} numSelected={selected.length} />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="documents table">
            <TableHead>
              <TableRow className={classes.headCell}>
                <TableCell> </TableCell>
                {headCells.map((headCell) =>
                  headCell.id !== 'documentLink' ? (
                    <TableCell
                      key={headCell.id}
                      align={'center'}
                      className={classes.tableCell}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}

                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ) : (
                    <TableCell
                      key={headCell.id}
                      align={'center'}
                      className={classes.tableCell}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.label}
                    </TableCell>
                  ),
                )}
              </TableRow>
              <TableRow className={classes.headCell}>
                <TableCell className={classes.tableCellBg} padding="checkbox">
                  {/* <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                  /> */}
                </TableCell>
                <TableCell align={'center'} className={classes.tableCell}>
                  <input
                    value={filters.documentName}
                    onChange={(e) => filterBySearch(e)}
                    name="documentName"
                    className={classes.inputCell}
                  />
                </TableCell>
                <TableCell align={'center'} className={classes.tableCell}>
                  <input
                    value={filters.featureId}
                    onChange={(e) => filterBySearch(e)}
                    name="featureId"
                    className={classes.inputCell}
                  />
                </TableCell>
                <TableCell align={'center'} className={classes.tableCell}>
                  <select
                    id="select-type"
                    value={filters.documentType}
                    onChange={(event) => setFilters({ ...filters, documentType: event.target.value })}
                    name="documentType"
                    className={classes.inputCell}
                  >
                    {journeyStore.claimNumberType === CLAIMTYPE.MOTOR &&
                      motorWithAll.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    {journeyStore.claimNumberType === CLAIMTYPE.HOME &&
                      homeWithAll.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    {journeyStore.claimNumberType === CLAIMTYPE.UNKNOWN &&
                      allOthersWithAll.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                  </select>
                </TableCell>
                <TableCell align={'center'} className={classes.tableCell}>
                  <input
                    value={filters.fileFormat}
                    onChange={(e) => filterBySearch(e)}
                    name="fileFormat"
                    className={classes.inputCell}
                  />
                </TableCell>
                <TableCell align={'center'} className={classes.tableCell}>
                  <input
                    value={filters.documentSource}
                    onChange={(e) => filterBySearch(e)}
                    name="documentSource"
                    className={classes.inputCell}
                  />
                </TableCell>
                <TableCell align={'center'} className={classes.tableCell}>
                  <input
                    value={filters.documentAuthor}
                    onChange={(e) => filterBySearch(e)}
                    name="documentAuthor"
                    className={classes.inputCell}
                  />
                </TableCell>
                <TableCell align={'center'} className={classes.tableCell}>
                  <div className={classes.dateCell}>
                    <p className={classes.label}>From:&nbsp;&nbsp;</p>
                    <input
                      id="dateFrom"
                      type="date"
                      value={filters.documentUploadDateFrom}
                      onChange={(e) => filterBySearch(e)}
                      name="documentUploadDateFrom"
                      className={classes.inputCell}
                    />
                  </div>
                  <div className={classes.dateCell}>
                    <p className={classes.label}>To:&nbsp;&nbsp;</p>
                    <input
                      id="dateTo"
                      type="date"
                      value={displayToDate}
                      onChange={(event) => {
                        const targetDate = new Date(event.target.value);
                        const nextDay = `${targetDate.getFullYear()}-${(targetDate.getMonth() + 1)
                          .toString()
                          .padStart(2, '0')}-${(targetDate.getDate() + 1).toString().padStart(2, '0')}`;
                        setFilters({ ...filters, documentUploadDateTo: nextDay });
                        setDisplayToDate(event.target.value);
                      }}
                      className={classes.inputCell}
                    />
                  </div>
                </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(filteredRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.documentId.replace(/,/g, ''));
                  const labelId = `documents-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.documentId}
                      className={classes.tableRow && `${disableRow && !isItemSelected ? classes.disabled : ''}`}
                      selected={isItemSelected}
                      onClick={(event) =>
                        handleClick(event, disableRow, row.documentName, row.documentId.replace(/,/g, ''))
                      }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                      </TableCell>

                      <TableCell component="th" scope="row" className={classes.tableCell}>
                        {row.documentName}
                      </TableCell>

                      <TableCell className={classes.tableCell} align="left">
                        {row.featureId}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {row.documentType}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {row.fileFormat}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.documentSource}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.documentAuthor}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {formatedDate(row.receivedOrSendingDate)}
                      </TableCell>

                      <TableCell className={classes.tableCell} align="center">
                        <React.Fragment>
                          {config.isPrizmDoc &&
                          prizmDocSupportedFileExtensions.includes(row.documentName.split('.').pop()) ? (
                            <Link
                              className={classes.link}
                              to={`/document?claimNumber=${claimNumber}&id=${row.documentId.replace(
                                /,/g,
                                '',
                              )}&documentName=${row.documentName}`}
                              target="_blank"
                            >
                              <Button className={classes.cellButton} variant="contained" endIcon={<Visibility />}>
                                View
                              </Button>
                            </Link>
                          ) : (
                            <Button
                              className={classes.cellButton}
                              endIcon={<ArrowDownward />}
                              variant="contained"
                              onClick={() => downloadFile(row.documentId.replace(/,/g, ''))}
                            >
                              Download
                            </Button>
                          )}
                        </React.Fragment>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          className={classes.paginationSection}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
