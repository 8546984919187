import { IDocument, ISpecificData } from '../../storageman';
import { ICreateDraftResponse, IParameters, ITemplate, ITemplateGroup } from '../../claims';
import { IDocumentPart } from '../../request/request';
import {
  ICamera,
  IDateTime,
  IExifMetadata,
  IExtractedMetadata,
  IGps,
  IImage,
  ISettings,
  IValueRef,
} from '../../extractedMetadataModel';

export const DEFAULT_TEMPLATE_RESPONSE: ICreateDraftResponse = {
  documentPartId: 0,
  payload: '',
  projectId: 0,
  subject: '',
  templatePaginationId: 0,
  templateSelectorId: 0,
};
export const DEFAULT_DOCUMENT_PART: IDocumentPart = {
  template: DEFAULT_TEMPLATE_RESPONSE,
};
export const DEFAULT_PARAMETERS: IParameters = { damageNumber: '', featureId: 0, featureNumber: '', partyId: '' };
export const DEFAULT_TEMPLATE: ITemplate = {
  approval: false,
  channel: '',
  documentPartId: 0,
  payload: '',
  payloadId: 0,
  projectId: 0,
  subject: '',
  templateDescription: '',
  templateId: 0,
  templateName: '',
  templatePaginationId: 0,
  templateSelectorId: 0,
};

export const DEFAULT_DRAFT_RESPONSE: ICreateDraftResponse = {
  documentPartId: 0,
  payload: '',
  projectId: 0,
  subject: '',
  templatePaginationId: 0,
  templateSelectorId: 0,
};

export const DEFAULT_GROUP_TEMPLATE: ITemplateGroup = {
  groupDescription: '',
  groupName: '',
  templates: [DEFAULT_TEMPLATE],
};

export const DEFAULT_SPECIFIC_DATA: ISpecificData = {
  cnfId: '',
  correspondenceDate: '',
  createDate: '',
  department: '',
  departmentArea: '',
  incidentDate: '',
  region: '',
  scannedBy: '',
  team: '',
  ucmDocumentSource: '',
};

export const DEFAULT_SETTINGS: ISettings = {
  aperture: '',
  shutterSpeed: '',
};
export const DEFAULT_CAMERA: ICamera = {
  lens: '',
  make: '',
  model: '',
  settings: DEFAULT_SETTINGS,
};

export const DEFAULT_DATETIME: IDateTime = {
  digitized: '',
  original: '',
};

export const DEFAULT_VALUEREF: IValueRef = {
  ref: '',
  value: '',
};

export const DEFAULT_GPS: IGps = {
  altitude: DEFAULT_VALUEREF,
  destBearing: DEFAULT_VALUEREF,
  imgDirection: DEFAULT_VALUEREF,
  latitude: DEFAULT_VALUEREF,
  longitude: DEFAULT_VALUEREF,
  speed: DEFAULT_VALUEREF,
};

export const DEFAULT_IMAGE: IImage = {
  length: 0,
  width: 0,
};

export const DEFAULT_EXIFMETADATA: IExifMetadata = {
  camera: DEFAULT_CAMERA,
  dateTime: DEFAULT_DATETIME,
  gps: DEFAULT_GPS,
  image: DEFAULT_IMAGE,
};

export const DEFAULT_EXTRACTED_METADATA: IExtractedMetadata = {
  exifMetadata: DEFAULT_EXIFMETADATA,
};

export const DEFAULT_DOCUMENT: IDocument = {
  claimNumber: '',
  comments: '',
  documentAuthor: '',
  documentDescription: '',
  documentExternalId: '',
  documentMetadataId: 0,
  documentName: '',
  documentSize: 0,
  documentSource: '',
  documentType: '',
  extractedMetadata: DEFAULT_EXTRACTED_METADATA,
  featureId: '',
  lastUpdateDateTime: '',
  mimeType: '',
  specificData: DEFAULT_SPECIFIC_DATA,
  uploadDateTime: '',
  version: 0,
};
