import SparkMD5 from 'spark-md5';

export function textTruncate(inputText: string, len = 45): string {
  if (inputText.length <= len) {
    return inputText;
  }
  return `${inputText.slice(0, len - 3)}...`;
}

export function isPostcodeValid(postcode: string): boolean {
  const postCodeRegEx = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;
  return postCodeRegEx.test(postcode.trim());
}

export function isEmailAddressValid(emailAddress: string): boolean {
  const emailRegex = /^.{1,100}@.{1,100}\..{1,100}$/;
  return emailRegex.test(emailAddress);
}

export function isMobilePhoneNumberValid(phoneNumber: string): boolean {
  const phoneNumberRegex = /^(\+44\s?7\d{3}|\(?07\d{3}\)?|0044\s?7\d{3}|44\s?7\d{3})\s?\d{3}\s?\d{3}$/;
  return phoneNumberRegex.test(phoneNumber);
}

export function isPhoneNumberValid(phoneNumber: string): boolean {
  const phoneNumberRegex = /^0[127]\d{8,9}$/g;
  return phoneNumberRegex.test(phoneNumber);
}

// Decoding base64 ⇢ UTF8
export function b64DecodeUnicode(str: string): string {
  const buff = new Buffer(str, 'base64');
  return buff.toString('utf-8');
}

export function utf8ToBase64Encode(str: string): string {
  const buff = new Buffer(str, 'utf-8');
  return buff.toString('base64');
}

export const computeChecksumMd5 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0;

    fileReader.onerror = function (): void {
      reject('MD5 computation failed - error reading the file');
    };

    function processChunk(chunk_start: number): void {
      const chunk_end = Math.min(file.size, chunk_start + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
    }

    fileReader.onload = function (e: ProgressEvent<FileReader>): void {
      spark.append(e.target?.result as ArrayBuffer);
      cursor += chunkSize;

      if (cursor < file.size) {
        processChunk(cursor);
      } else {
        resolve(btoa(spark.end(true)));
      }
    };

    processChunk(0);
  });
};

export const fileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

export const formatedDate = (fullDate: string): string => {
  return `${fullDate.split(/-|:|T/)[2]}/${fullDate.split(/-|:|T/)[1]}/${fullDate.split(/-|:|T/)[0]} ${
    fullDate.split(/-|:|T/)[3]
  }:${fullDate.split(/-|:|T/)[4]}`;
};

export const bytesToMb = (valueInBytes: string | number): string => {
  try {
    const conv = 1048576;
    const realValueInBytes: number = +valueInBytes;
    const mbValueInBytes: number = realValueInBytes / conv;
    return mbValueInBytes.toFixed(2);
  } catch {
    return '0';
  }
};
