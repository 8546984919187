const config = {
  api: process.env.REACT_APP_API_DOCGEN ?? '',
  apiLocalFlag: process.env.REACT_APP_API_LOCAL_FLAG === '1' ? true : false,
  apiv2: process.env.REACT_APP_API_DOCGEN_V2 ?? '',
  attachFilesMaxSize: process.env.REACT_APP_ATTACH_FILES_MAX_SIZE ?? '',
  build: {
    id: process.env.REACT_APP_BUILD_ID ?? '',
    version: process.env.REACT_APP_VERSION ?? '',
  },

  featureToggle: {
    enableAttachFile: process.env.REACT_APP_ENABLE_ATTACH_FILES === 'true' ? true : false,
  },
  isPrizmDoc: process.env.REACT_APP_PRIZM_DOC_ENABLE === 'true' ? true : false,
  isProduction: true,
  localStateKey: process.env.REACT_APP_STATE_KEY ?? 'es_claims_docgen_state',
  okta: {
    okta_clientId: process.env.REACT_APP_OKTA_CLIENTID ?? '',
    okta_issuer: process.env.REACT_APP_OKTA_ISSUER ?? '',
    okta_redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI ?? '',
  },
  prizmDocClourOrOnPrem: process.env.REACT_APP_PRIZM_DOC_CLOUD_OR_ONPREM === 'cloud' ? 'cloud' : 'onprem',
  prizmDocPasProxy: process.env.REACT_APP_XAPI_DOCGEN_PASPROXY ?? 'http://localhost:2000',
  provider: {
    analyticKey: process.env.REACT_APP_PROVIDER_ANALYTIC_KEY ?? '',
    paymentEnv: process.env.REACT_APP_PROVIDER_PAYMENT_ENV ?? '',
    paymentKey: process.env.REACT_APP_PROVIDER_PAYMENT_KEY ?? '',
  },
  smartcom: {
    authParams: process.env.REACT_APP_SMARTCOMM_AUTH_PARAMS ?? '',
    authPopup: false,
    authUrl: process.env.REACT_APP_SMARTCOMM_AUTH_URL ?? '',
    context: process.env.REACT_APP_SMARTCOMM_EDITOR_CONTEXT ?? '',
    editor: process.env.REACT_APP_SMARTCOMM_EDITOR_JS ?? '',
    server: process.env.REACT_APP_SMARTCOMM_SERVER ?? '',
  },
  storageManApiEndpoint: {
    documents: process.env.REACT_APP_API_STORAGEMAN ?? '',
  },
  xapi: process.env.REACT_APP_XAPI_DOCGEN ?? 'http://localhost:2000',
  xmlCases: {
    docGenReviewCase: process.env.REACT_APP_XML_CASE ?? '',
  },
};

if (process.env.REACT_APP_SMARTCOMM_AUTH_POPUP === 'true') {
  config.smartcom.authPopup = true;
}

if (process.env.NODE_ENV !== 'production') {
  config.isProduction = false;
}

export default config;
