import { observer } from 'mobx-react-lite';
import { Search as SearchView } from '../../../component/search';
import { Container, Grid } from '@esure-cloud/react-components';

export const Search: React.FC = observer(() => {
  return (
    <Container maxWidth="lg">
      <Grid alignItems="center" container id="find">
        <SearchView />
      </Grid>
    </Container>
  );
});
