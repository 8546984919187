import config from './config';
import axios from 'axios';
import { AccessToken } from './claims';
import { IExtractedMetadata } from './extractedMetadataModel';

export interface CoreApiResponse<T> {
  buildVersion: string;
  endpointVersion: string;
  errors: string[];
  infos: string[];
  results: T;
}

export interface IClaim {
  buildVersion: string;
  endpointVersion: string;
  errors: [];
  infos: IInfos[];
  results: IDocument[];
}

export interface IInfos {
  code: string;
  detail: string;
  summary: string;
  traceId: string;
}

export interface IErrors {
  code: string;
  detail: string;
  stackTrace: string;
  summary: string;
  traceId: string;
}

export interface ISpecificData {
  cnfId: string;
  correspondenceDate: string;
  createDate: string;
  department: string;
  departmentArea: string;
  incidentDate: string;
  region: string;
  scannedBy: string;
  team: string;
  ucmDocumentSource: string;
}

export interface IDocument {
  claimNumber: string;
  comments: string | null;
  documentAuthor: string | null;
  documentDescription: string | null;
  documentExternalId: string | null;
  documentMetadataId: number;
  documentName: string | null;
  documentSize: number;
  documentSource: string | null;
  documentType: string;
  extractedMetadata: IExtractedMetadata | null;
  featureId: string | null;
  lastUpdateDateTime: string;
  mimeType: string;
  specificData: ISpecificData;
  uploadDateTime: string;
  version: number;
}

export interface IPresignedURLResults {
  expires: string;
  url: string;
}

export interface IStoreDocumentMetaData {
  documentMetadataId: number;
}

export interface IRequest {
  'Content-MD5': string;
}

export interface IPresignedURL {
  buildVersion: string;
  endpointVersion: string;
  errors: IErrors[];
  infos: IInfos[];
  results: IPresignedURLResults[];
}

export interface IStoreDocument {
  buildVersion: string;
  endpointVersion: string;
  errors: IErrors[];
  infos: IInfos[];
  results: IStoreDocumentMetaData[];
}

export interface IStoreDocumentFromExternalUrl {
  comments: string;
  documentAuthor: string;
  documentDescription: string;
  documentExternalId?: string;
  documentLocation: string;
  documentName: string;
  documentSource: string;
  documentType: string;
  featureId: string | null;
  mimeType: string;
  specificData?: ISpecificData;
}

export interface IUpdateClaim {
  claimNumber: number;
  version: number;
}

export interface IPatchDocMetadata {
  comments: string;
  documentDescription: string;
  documentName: string;
  documentType: string;
  featureId: string;
  version: number;
}

export interface IClaimRetrieveDocumentWithUrl {
  document: string | null;
  documentExternalId: string;
  documentLocation: string;
  documentMetadataId: number;
  documentName: string;
  documentType: string;
  expires: string;
  lastUpdateDateTime: string;
  mimeType: string;
  version: number;
}

const axiosStorageManInstance = axios.create({});

const axiosStorageManInstanceTwo = axios.create({});

axiosStorageManInstance.interceptors.request.use(
  function (conf) {
    // Do something before request is sent

    const oktaTokenStorage = localStorage.getItem('okta-token-storage');
    if (oktaTokenStorage) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { accessToken }: { accessToken: AccessToken } = JSON.parse(oktaTokenStorage);
      const token: string = accessToken.accessToken;
      conf.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return conf; // eslint-disable-line @typescript-eslint/no-unsafe-return
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

export const storageManService = {
  getPresignedURL: (documentSize: string, md5: string): Promise<{ data: IPresignedURL }> => {
    return axiosStorageManInstance.get(
      `${config.storageManApiEndpoint.documents}/claim/generate-document-upload-url/?documentSize=${documentSize}&md5=${md5}`,
    );
  },

  getSearchDocument: (claimId: string): Promise<{ data: IClaim }> => {
    return axiosStorageManInstance.get(`${config.storageManApiEndpoint.documents}/claim/${claimId}/search-document`);
  },

  retrieveDocument: (
    documentMetadataId: string,
  ): Promise<{ data: CoreApiResponse<IClaimRetrieveDocumentWithUrl[]> }> => {
    return axiosStorageManInstance.get(
      `${config.storageManApiEndpoint.documents}/claim/retrieve-document/${documentMetadataId}?documentRepresentation=URL&urlDuration=PT1M`,
    );
  },

  updateDocumentToClaim: (updateClaim: IUpdateClaim, selectedDocumentID: number): Promise<{ data: IUpdateClaim }> => {
    return axiosStorageManInstance.patch(
      `${config.storageManApiEndpoint.documents}/claim/update-metadata/${selectedDocumentID}`,
      updateClaim,
    );
  },

  updateMetadata: (docFromStore: IPatchDocMetadata, selectedDocumentID: string): Promise<{ data: IClaim }> => {
    return axiosStorageManInstance.patch(
      `${config.storageManApiEndpoint.documents}/claim/update-metadata/${selectedDocumentID}`,
      docFromStore,
    );
  },

  uploadDocumentToPresignedUrl: (
    url: string,
    headers: IRequest,
    file: File | null | undefined,
  ): Promise<{ data: void }> => {
    return axiosStorageManInstanceTwo.put(url, file, { headers });
  },

  uploadDocumentToStorageman: (
    claimId: string,
    documentToStore: IStoreDocumentFromExternalUrl,
  ): Promise<{ data: IStoreDocument }> => {
    return axiosStorageManInstance.post(
      `${config.storageManApiEndpoint.documents}/claim/${claimId}/store-document`,
      documentToStore,
    );
  },
};
