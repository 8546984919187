import { ROUTE, ROUTE_TEMPLATE_STEP } from './constant';
import { Template } from '../view/search/template';
import { Confirmation, Editor, Search } from '../view/search/step';
import { Documents } from '../view/documents/documents';
import { Attachment } from '../view/attachment/attachment';
import { Document } from '../view/document/document';

const routeConfig = {
  [ROUTE.TEMPLATE]: { component: Template },
  [ROUTE.DOCUMENTS]: { component: Documents },
  [ROUTE.DOCUMENT]: { component: Document },
  [ROUTE.ATTACHMENT]: { component: Attachment },
};

const routeConfigSearchStep = {
  [ROUTE_TEMPLATE_STEP.SEARCH]: { component: Search, exact: true },
  [ROUTE_TEMPLATE_STEP.EDITOR]: { component: Editor, exact: true },
  [ROUTE_TEMPLATE_STEP.CONFIRMATION]: { component: Confirmation, exact: true },
};

export const expandRoutePath = (path = ''): string => `${process.env.PUBLIC_URL}${path}`;

export const routeList = Object.entries(routeConfig).map(([path, r]) => ({ path, ...r }));

export const routeListSearchStep = Object.entries(routeConfigSearchStep).map(([path, r]) => ({ path, ...r }));
