import { Instance, types } from 'mobx-state-tree';

export const selectedDoc = types.model({
  object: types.string,
});

export const DocumentStore = types
  .model('DocumentStore', {
    documentAuthor: types.maybeNull(types.string),
    documentComments: types.optional(types.string, ''),
    documentDescription: types.optional(types.string, ''),
    documentFormat: types.optional(types.string, ''),
    documentMetadataId: types.optional(types.string, ''),
    documentName: types.optional(types.string, ''),
    documentSize: types.optional(types.number, 0),
    documentType: types.optional(types.string, ''),
    featureId: types.maybeNull(types.string),
    md5: types.optional(types.string, ''),

    presignedURL: types.optional(types.string, ''),
    version: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setDocumentAuthor(val: string) {
      self.documentAuthor = val;
    },
    setDocumentComments(val: string) {
      self.documentComments = val;
    },
    setDocumentDescription(val: string) {
      self.documentDescription = val;
    },
    setDocumentFormat(val: string) {
      self.documentFormat = val;
    },
    setDocumentMetadataId(val: string) {
      self.documentMetadataId = val;
    },
    setDocumentName(val: string) {
      self.documentName = val;
    },
    setDocumentSize(val: number) {
      self.documentSize = val;
    },
    setDocumentType(val: string) {
      self.documentType = val;
    },
    setFeatureId(val: string) {
      self.featureId = val;
    },
    setMd5(val: string) {
      self.md5 = val;
    },
    setPresignedURL(val: string) {
      self.presignedURL = val;
    },
    setVersion(val: number) {
      self.version = val;
    },
  }))
  .views((self) => ({
    getDocumentAuthor(): string | null {
      return self.documentAuthor;
    },
    getDocumentComments(): string {
      return self.documentComments;
    },
    getDocumentDescription(): string {
      return self.documentDescription;
    },
    getDocumentFormat(): string {
      return self.documentFormat;
    },
    getDocumentMetadataId(): string {
      return self.documentMetadataId;
    },
    getDocumentName(): string {
      return self.documentName;
    },
    getDocumentSize(): number {
      return self.documentSize;
    },
    getDocumentType(): string {
      return self.documentType;
    },
    getFeatureId(): string | null {
      return self.featureId;
    },
    getMd5(): string {
      return self.md5;
    },
    getPresignedURL(): string {
      return self.presignedURL;
    },
    getVersion(): number {
      return self.version;
    },
  }));

export type IDocumentStore = Instance<typeof DocumentStore>;
