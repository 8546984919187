export interface Data {
  documentAuthor: string;
  documentId: string;
  documentLink: string;
  documentName: string;
  documentSource: string;
  documentType: string;
  featureId: string;
  fileFormat: string;
  mimeType: string;
  receivedOrSendingDate: string;
  uploadDateTime: string;
}

export function createData(
  documentAuthor: string,
  documentId: string,
  documentLink: string,
  documentName: string,
  documentSource: string,
  documentType: string,
  featureId: string,
  fileFormat: string,
  receivedOrSendingDate: string,
  mimeType: string,
  uploadDateTime: string,
): Data {
  return {
    documentAuthor,
    documentId,
    documentLink,
    documentName,
    documentSource,
    documentType,
    featureId,
    fileFormat,
    mimeType,
    receivedOrSendingDate,
    uploadDateTime,
  };
}

export interface Filters {
  documentAuthor: string;
  documentName: string;
  documentSource: string;
  documentType: string;
  documentUploadDateFrom: string;
  documentUploadDateTo: string;
  featureId: string;
  fileFormat: string;
}
