interface IAsyncSessionStorage {
  clear(): Promise<void>;
  getItem(key: string): Promise<string | null>;
  removeItem(key: string): Promise<void>;
  setItem(key: string, value: string): Promise<void>;
}

const asyncWrap = <ResponseType>(fn: () => ResponseType): Promise<ResponseType> => {
  try {
    return Promise.resolve(fn());
  } catch (err) {
    return Promise.reject(err);
  }
};

export const AsyncSessionStorage: IAsyncSessionStorage = {
  clear: () => asyncWrap(() => window.sessionStorage.clear()),
  getItem: (key) => asyncWrap(() => window.sessionStorage.getItem(key)),
  removeItem: (key) => asyncWrap(() => window.sessionStorage.removeItem(key)),
  setItem: (key, value) => asyncWrap(() => window.sessionStorage.setItem(key, value)),
};
