/* eslint-disable */
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Typography,
} from '@esure-cloud/react-components';
import config from '../../../service/config';
import { useStores } from '../../../service/state/store';
import { b64DecodeUnicode, utf8ToBase64Encode } from '../../../service/util/utils';
import {
  Action,
  Channel,
  FEAction,
  IDocGenCustomer,
  IDocGenRecipient,
  IDocumentPart,
  IDraftRequest,
  IFile,
  ITemplate,
  ReferenceType,
  Type,
} from '../../../service/request/request';
import { ROUTE, ROUTE_TEMPLATE_STEP } from '../../../service/constant';
import {
  claimsService,
  ICreateDraftGroupRequest,
  ICreateDraftRequest,
  ICreateDraftResponse,
  IParameters,
  ITemplateGroup,
} from '../../../service/claims';
import { IParty, mapCreateDraftGroupToCrateDraft } from '../../../component/search';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import Send from '@material-ui/icons/Send';

import { TemplatePaginator } from '../../../component/templatePaginator';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

let smartEditor: any;

export function IncludeEditor(revCase: string) {
  let editor: any;
  let draftEditorWindowEl: any;
  let authParams: string = '';
  //const reviewCase = revCase;

  function renderDraftEditor() {
    if (editor) {
      editor.destroy();
    }

    if (config.smartcom.authParams) {
      if (config.smartcom.authPopup) {
        authParams = 'samlIdpLoginPopup=true&';
      }

      authParams =
        authParams +
        'targetURL=' +
        config.smartcom.authParams +
        '?clientServer=' +
        encodeURI(location.protocol + '//' + location.host);
    }
    // @ts-ignore
    SmartComms.DraftEditor.create({
      context: config.smartcom.context,
      server: config.smartcom.server,
      clientServer: location.protocol + '//' + location.host,
      authUrl: config.smartcom.authUrl,
      authParams: authParams,
      targetElementID: 'application',
      loadStartupConfig: function () {
        return {
          features: {
            disabled: [
              'InsertImage',
              'InsertLink',
              'InsertEquation',
              'InsertSharedContent',
              'InsertPageBreak',
              'InsertPageNumbering',
              'InsertFootnote',
              'InsertFrag',
              'AddComment',
              'Save',
              'Preview',
            ],
            // defaultSpellcheckLanguageId: '',
            showTrackChanges: false,
            disableInlineEditing: false,
            focusOnFirstEditPoint: true,
            forcePreviewFormat: 'PDF',
            collapseChoiceLists: false,
          },
          version: '2',
        };
      },
    }).then(function (createdEditor: any) {
      editor = createdEditor;
      loadDraftFromString(revCase);
    });
  }

  function resizeApplicationDIV() {
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const contentHeight = viewportHeight - draftEditorWindowEl.offsetTop;
    draftEditorWindowEl.style.height = contentHeight + 'px';
  }

  window.onload = init;

  function init() {
    draftEditorWindowEl = document.getElementById('application');
    renderDraftEditor();
    resizeApplicationDIV();
    window.onresize = resizeApplicationDIV;
  }

  function loadDraftFromString(reviewCase: string) {
    const draftString = reviewCase;
    const openInPreview = false;
    if (draftString) {
      editor.v3
        .loadString(draftString, { openInPreview: openInPreview })
        .then(function (loadSuccess: any) {
          console.log('Load String called back.\nLoad success: ' + loadSuccess);
        })
        .catch(function () {
          console.log('API call failed');
        });
    } else {
      console.log('You must specify a string to load');
    }
  }

  function getDraftAsString() {
    console.log('getDraftAsString');
    return editor.v3.saveToString();
  }

  return {
    init: init,
    getDraftAsString: getDraftAsString,
    loadDraftFromString: loadDraftFromString,
  };
}

export const Editor: React.FC = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { interfaceStore, journeyStore, claimsStore } = useStores();
  journeyStore.setPage('EDITOR');
  interfaceStore.docGenSearch.setActiveStepIndex(1);

  const [subjectEditState, setSubjectEditState] = useState(false);
  const [subject, setSubject] = useState(claimsStore.getSelectedTemplateResponse().subject ?? '');
  const [recipientUser, setRecipientUser] = useState<IParty>(interfaceStore.partyFull.getPartyFull());
  const [parameters, setParameters] = useState<IParameters>(claimsStore.getSelectedParameters());
  const [template, setTemplate] = useState<ITemplate>(claimsStore.getSelectedTemplate());
  const [draftResponse, setDraftResponse] = useState<ICreateDraftResponse>(claimsStore.getSelectedTemplateResponse());
  const claimNumber = interfaceStore.docGenSearch.claimNumber;
  const channel = interfaceStore.docGenSearch.selectedChannel;
  const selectedChannel: Channel = Channel[channel as keyof typeof Channel];
  const [currentTemplateNum, setCurrentTemplateNum] = useState(claimsStore.currentTemplate);
  const [numberOfTemplatesNum, setNumberOfTemplatesNum] = useState(claimsStore.numberOfTemplates);

  const routeChange = (route: string) => {
    history.push({ pathname: route, search: '?claimNumber=' + claimNumber });
  };

  const mapToCreateRequest = (
    action: Action,
    type: Type,
    channel: Channel,
    paramet: IParameters,
    templat: ITemplate,
    name: string,
    draftResp: ICreateDraftResponse,
    savedDocumentList: (IDocumentPart | IFile)[],
    savedDocument: string,
    claimNumber: string,
    recipientMap: IParty,
    subj: string,
    oktaEmail: string,
    oktaName: string,
  ) => {
    const sender: IDocGenCustomer = { email: oktaEmail, name: oktaName };
    const recipient: IDocGenRecipient = {
      email: recipientMap.email ?? '',
      name: recipientMap.displayValue,
      phoneNumber: recipientMap.phone ?? '',
    };
    const template: ITemplate = {
      templatePaginationId: draftResp.templatePaginationId,
      payload: savedDocument,
      projectId: templat.projectId,
      subject: subj,
      templateSelectorId: +draftResp.templateSelectorId,
      documentPartId: draftResp.documentPartId,
    };
    const document: IDocumentPart = { template };

    const request: IDraftRequest = {
      action: action,
      channel: channel,
      referenceNumber: claimNumber,
      referenceType: ReferenceType.CLAIM,
      sender: sender,
      document: savedDocumentList,
      name: name,
      featureId: paramet.featureId,
      recipient: recipient,
      type: type,
    };
    return request;
  };

  const mapToDocumentPart = (
    savedDocument: string,
    templat: ITemplate,
    draftResp: ICreateDraftResponse,
    subj: string,
  ) => {
    const template: ITemplate = {
      templatePaginationId: draftResp.templatePaginationId,
      payload: savedDocument,
      projectId: templat.projectId,
      subject: subj,
      templateSelectorId: +draftResp.templateSelectorId,
      documentPartId: draftResp.documentPartId,
    };
    const document: IDocumentPart = { template };
    return document;
  };

  const onSendGenerate = (
    claimNumber: string,
    action: Action,
    channel: Channel,
    param: IParameters,
    templ: ITemplate,
    draftResp: ICreateDraftResponse,
    recipient: IParty,
    subj: string,
    currTemplNum: number,
    numOfTemplNum: number,
    feAction: FEAction,
  ) => {
    executeSaveDraft(
      claimNumber,
      action,
      channel,
      param,
      templ,
      draftResp,
      recipient,
      subj,
      currTemplNum,
      numOfTemplNum,
      feAction,
      0,
    );
  };

  function executeSaveDraft(
    claimNumber: string,
    action: Action,
    channel: Channel,
    param: IParameters,
    templ: ITemplate,
    draftResp: ICreateDraftResponse,
    recipient: IParty,
    subj: string,
    currTemplNum: number,
    numOfTemplNum: number,
    feAction: FEAction,
    nextTemplate: number,
  ) {
    let savedDraft = '';
    smartEditor
      .getDraftAsString()
      .then(function (result: any) {
        console.log(
          'Save To String called back successfully with ' + result.validationErrors.length + ' validation errors',
        );

        if (result.validationErrors && result.validationErrors.length > 0) {
          console.log('Transition to next step is blocked due to validation errors');
        } else {
          savedDraft = result.draftXML;

          const encodedSavedDraft = utf8ToBase64Encode(savedDraft);

          const documentForStore = mapToDocumentPart(encodedSavedDraft, templ, draftResp, subj);
          claimsStore.setEditedTemplateResponceList(documentForStore.template);

          if (currTemplNum === numOfTemplNum && feAction === FEAction.PUSH) {
            const allEditedTemplates: ICreateDraftResponse[] = claimsStore.getEditedTemplateResponceList();

            let documentRequestList: (IDocumentPart | IFile)[] = [];

            const documentList: IDocumentPart[] = allEditedTemplates.map((template) => {
              return { template: template };
            });

            let fileList: IFile[] = [];
            if (config.featureToggle.enableAttachFile) {
              const allAttachedFiles: string[] = claimsStore.getSelectedDocumentMetadataId();
              fileList = allAttachedFiles.map((value) => {
                return { file: { documentMetadataId: value } };
              });
            }

            documentRequestList = [...documentList, ...fileList];

            const req = mapToCreateRequest(
              action,
              Type.MANUAL,
              channel,
              param,
              templ,
              claimsStore.getGroupTemplate().groupName,
              draftResp,
              documentRequestList,
              encodedSavedDraft,
              claimNumber,
              recipient,
              subj,
              claimsStore.oktaEmail,
              claimsStore.oktaName,
            );
            if (req.featureId == 0) {
              delete req.featureId;
            }
            claimsService.request(claimNumber, req).then((response) => {
              claimsStore.setDocumentMetadataId([]);
              routeChange(ROUTE_TEMPLATE_STEP.CONFIRMATION);
            });
          }

          goToNextStep(feAction, nextTemplate);
        }
      })
      .catch(function () {
        console.log('API call failed');
      });
  }

  const goToNextStep = (feAction: FEAction, nextTemplate: number) => {
    if (feAction === FEAction.ITERATE) {
      // fetch and store next template
      claimsStore.setCurrentTemplate(nextTemplate);
      setCurrentTemplateNum(nextTemplate);

      const templateGroup: ITemplateGroup = claimsStore.getGroupTemplate();
      const createDraftGrouped: ICreateDraftGroupRequest = {
        parameters,
        templateGroup,
      };

      const createDraft: ICreateDraftRequest = mapCreateDraftGroupToCrateDraft(nextTemplate - 1, createDraftGrouped);

      claimsService.createDraft(claimNumber, createDraft).then((response) => {
        response.data.templatePaginationId = nextTemplate - 1;
        claimsStore.setSelectedTemplateResponse(response.data);

        refreshPage();
      });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = config.smartcom.editor;
    script.async = true;
    script.onload = () => {
      document.getElementById('application');
      const reviewCaseXml = b64DecodeUnicode(claimsStore.getSelectedTemplateResponse().payload);
      smartEditor = IncludeEditor(reviewCaseXml);
      smartEditor.init();
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSubjectChange = (e: any) => {
    e.target.value ? setSubject(e.target.value) : setSubject(' ');
    console.log('handleSubjectChange function runs');
  };

  const handleSaveSubject = () => {
    if (subject === ' ') {
      setSubject('');
    }
    setSubjectEditState(false);
  };

  const clickTemplateNumberChange = (nextTemplate: number) => {
    executeSaveDraft(
      claimNumber,
      Action.DELIVER,
      selectedChannel,
      parameters,
      template,
      draftResponse,
      recipientUser,
      subject,
      currentTemplateNum,
      numberOfTemplatesNum,
      FEAction.ITERATE,
      nextTemplate,
    );
  };

  const refreshPage = () => {
    const reviewCaseXml = b64DecodeUnicode(claimsStore.getSelectedTemplateResponse().payload);
    if (smartEditor) {
      smartEditor.loadDraftFromString(reviewCaseXml);
    }
    setSubject(claimsStore.getSelectedTemplateResponse().subject ?? '');
    setDraftResponse(claimsStore.getSelectedTemplateResponse());
    setCurrentTemplateNum(claimsStore.getCurrentTemplateNum());
  };

  return (
    <>
      <TemplatePaginator onClick={clickTemplateNumberChange}></TemplatePaginator>

      {selectedChannel === Channel.SMS && (
        <Alert color="warning" variant="outlined">
          Messages should only be sent within the business hours 8:00 am - 20:00 hrs, except in exceptional
          circumstances.
        </Alert>
      )}

      {selectedChannel !== Channel.LETTER && currentTemplateNum === numberOfTemplatesNum && (
        <Button
          variant="contained"
          endIcon={<Send />}
          size="large"
          color="secondary"
          className={classes.button}
          onClick={() =>
            onSendGenerate(
              claimNumber,
              Action.DELIVER,
              selectedChannel,
              parameters,
              template,
              draftResponse,
              recipientUser,
              subject,
              currentTemplateNum,
              numberOfTemplatesNum,
              FEAction.PUSH,
            )
          }
        >
          Send
        </Button>
      )}
      {selectedChannel === Channel.LETTER && currentTemplateNum === numberOfTemplatesNum && (
        <Button
          variant="contained"
          endIcon={<Send />}
          size="large"
          color="secondary"
          className={classes.button}
          onClick={() =>
            onSendGenerate(
              claimNumber,
              Action.GENERATE,
              selectedChannel,
              parameters,
              template,
              draftResponse,
              recipientUser,
              subject,
              currentTemplateNum,
              numberOfTemplatesNum,
              FEAction.PUSH,
            )
          }
        >
          Generate
        </Button>
      )}
      {currentTemplateNum === numberOfTemplatesNum &&
        config.featureToggle.enableAttachFile &&
        selectedChannel === Channel.EMAIL && (
          <Button
            variant="contained"
            endIcon={<EditIcon />}
            size="large"
            color="secondary"
            className={classes.button}
            onClick={() => routeChange(ROUTE.ATTACHMENT)}
          >
            Attach files
          </Button>
        )}
      {selectedChannel === Channel.EMAIL && currentTemplateNum === 1 && (
        <>
          {subject ? (
            <Paper component="form" className={classes.paper}>
              {subjectEditState ? (
                <>
                  <InputBase autoFocus className={classes.input} value={subject} onChange={handleSubjectChange} />
                  <IconButton onClick={handleSaveSubject}>
                    <SaveIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Typography className={classes.input} variant="h3" component="h3">
                    {subject}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setSubjectEditState(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </>
              )}
            </Paper>
          ) : (
            <Button
              color="secondary"
              onClick={() => {
                setSubject('New Subject');
                setSubjectEditState(true);
              }}
            >
              Add Subject
            </Button>
          )}
        </>
      )}
      <Grid alignItems="center" container id="find">
        <div id="application" style={{ width: '100%', height: '100%' }}></div>
      </Grid>
    </>
  );
});
