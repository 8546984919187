import { types } from 'mobx-state-tree';
import { IInterfaceStore, InterfaceStore } from './interfaceStore';
import { IJourneyStore, JourneyStore } from './journey/journey';
import { DocumentStore, IDocumentStore } from './document/documentStore';
import { ClaimsStore, IClaimsStore } from './claims/claimStore';

export const RootStore = types.model('RootStore', {
  claimsStore: types.optional(ClaimsStore, () => ClaimsStore.create()),
  documentStore: types.optional(DocumentStore, () => DocumentStore.create()),
  interfaceStore: types.optional(InterfaceStore, () => InterfaceStore.create()),
  journeyStore: types.optional(JourneyStore, () => JourneyStore.create()),
});

// generate an approximation of the RootStore instance type without using the RootStore object
// this solves circular dependency issues when nested stores wish to reach out to the root
export interface IRootStore {
  claimsStore: IClaimsStore;
  documentStore: IDocumentStore;
  interfaceStore: IInterfaceStore;
  journeyStore: IJourneyStore;
}
