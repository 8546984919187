import React, { useState } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Step, StepLabel, Stepper } from '@material-ui/core';

import { DocumentMetadataUploadOrUpdateForm } from './documentMetadataUploadOrUpdateForm';
import { useStores } from '../../service/state/store';
import { DocumentUploadConfirmation } from './documentUploadConfirmation';
import { IStoreDocumentFromExternalUrl, storageManService } from '../../service/storageman';
import { DocumentFileUploadOrUpdateForm } from './documentFileUploadOrUpdateForm';
import { resetDocumentState } from './documentUploadService';
import { useQuery } from '../../service/network/useQueryString';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    aa: {
      height: '100%',
    },
    actionButton: {
      borderRadius: '8px',
      height: '48px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '200px',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.main,
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    instructions: {
      margin: '2rem 0 auto',
    },
    marginTopBottom: {
      margin: '2rem 0',
    },
    root: {
      [theme.breakpoints.down('sm')]: {
        height: '90vh',
      },
      [theme.breakpoints.up('md')]: {
        height: '70vh',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: '2rem',
      width: '100%',
    },
    step: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  }),
);

function getSteps() {
  return ['Step 1 - Upload document', 'Step 2 - “Document Metadata'];
}

interface ModalProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HorizontalLabelPositionBelowStepper: React.FC<ModalProps> = ({ setOpenModal }) => {
  const { claimsStore, documentStore } = useStores();
  const classes = useStyles();
  const steps = getSteps();
  const query = useQuery();
  const claimNumber: string = query.get('claimNumber') ?? '';

  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState<File | null | undefined>();

  const [formValid, setFormValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const [docTypeValid, setDocTypeValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);

  const handleNext = () => {
    documentStore.getDocumentName() === ''
      ? setError('Please select a file to upload')
      : setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    resetDocumentState(documentStore);
  };

  const CancelAndExit = () => {
    handleReset();
  };

  const handleClose = () => {
    setOpenModal(false);
    storageManService.getSearchDocument(claimNumber).then((response) => {
      claimsStore.setIncomingDocuments(response.data.results);
    });
  };

  const handleCancel = () => {
    documentStore.getDocumentName() !== ''
      ? window.confirm('Are you sure? you will lose your progress') && CancelAndExit()
      : setOpenModal(false);
  };

  const handleError = () => {
    setShowError(true);
  };

  const handleSubmit = () => {
    setLoading(true);

    formValid
      ? // 1. get presigned URL
        storageManService
          .getPresignedURL(documentStore.getDocumentSize().toString(), documentStore.getMd5())
          .then((response) => {
            documentStore.setPresignedURL(response.data.results[0].url);

            const url = documentStore.getPresignedURL();
            const md5store = documentStore.getMd5().toString();
            const headers = {
              'Content-MD5': md5store,
            };

            const docToStore: IStoreDocumentFromExternalUrl = {
              comments: documentStore.getDocumentComments(),
              documentAuthor: documentStore.getDocumentAuthor(),
              documentDescription: documentStore.getDocumentDescription(),
              documentLocation: url,
              documentName: documentStore.getDocumentName(),
              documentSource: 'DOCGEN PORTAL',
              documentType: documentStore.getDocumentType(),
              featureId: documentStore.getFeatureId(),
              mimeType: file?.type ?? '',
            };

            storageManService
              .uploadDocumentToPresignedUrl(url, headers, file)
              .then(() => {
                // 3. set file from presigned URL and store it in Storageman
                storageManService
                  .uploadDocumentToStorageman(claimNumber, docToStore)
                  .then((response) => {
                    documentStore.setDocumentMetadataId(response.data.results[0].documentMetadataId.toString());
                    setLoading(false);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  })
                  .catch((error) => {
                    setError(error);
                    setLoading(false);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  });
                setLoading(false);
              })
              .catch((error) => {
                setError(error);
                setLoading(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              });
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          })
      : handleError();
  };

  const ErrorMessages = () => {
    return (
      <div>
        {!docTypeValid && <div className={classes.error}>Document type is required</div>}
        {!nameValid && <div className={classes.error}>Document name is required</div>}
      </div>
    );
  };

  const SubmitError = () => {
    return (
      <div className={classes.marginTopBottom}>
        <div className={classes.error}>Something went wrong, please try again</div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.aa}>
        {activeStep === steps.length ? (
          <div className={classes.step}>
            {error ? <SubmitError /> : <DocumentUploadConfirmation loading={loading} />}
            <div>
              {/* <Button variant="outlined" onClick={handleReset} className={classes.actionButton}>
                Upload another
              </Button> */}
              <Button variant="contained" onClick={handleClose} className={classes.actionButton}>
                Close
              </Button>
            </div>
          </div>
        ) : (
          <div className={classes.step}>
            {activeStep === 0 && (
              <div className={classes.instructions}>
                <DocumentFileUploadOrUpdateForm setFile={setFile} error={error} setError={setError} />
              </div>
            )}
            {activeStep === 1 && (
              <div className={classes.instructions}>
                {showError && <ErrorMessages />}
                <DocumentMetadataUploadOrUpdateForm
                  docTypeValid={setDocTypeValid}
                  nameValid={setNameValid}
                  setFormValid={setFormValid}
                />
              </div>
            )}
            <div className={classes.flex}>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.actionButton}>
                  Back
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button variant="contained" className={classes.actionButton} onClick={handleSubmit}>
                    Submit
                  </Button>
                ) : (
                  <Button variant="contained" className={classes.actionButton} onClick={handleNext}>
                    Next
                  </Button>
                )}
              </div>
              <div>
                <Button onClick={handleCancel} color="primary" className={classes.actionButton}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
