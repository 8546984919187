import React, { useMemo } from 'react';
import {
  Backdrop,
  Button,
  Container,
  createStyles,
  Fade,
  lighten,
  makeStyles,
  Modal,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@esure-cloud/react-components';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { DocumentFileMoveForm } from '../document/documentFileMoveForm';
import { HorizontalLabelPositionBelowStepper } from '../document/documentUploadStepper';
import { resetDocumentState, updateDocumentState, uploadDocumentState } from '../document/documentUploadService';
import { useStores } from '../../service/state/store';
import { UpdateMetaDataStepper } from '../document/documentUpdateMetaDataStepper';
import { IDocument } from '../../service/storageman';
import { IExtractedMetadata } from '../../service/extractedMetadataModel';
import { DEFAULT_EXTRACTED_METADATA } from '../../service/state/mock/stateMock';
import { formatedDate } from '../../service/util/utils';
import ReactJson from 'react-json-view';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      borderRadius: '8px',
      height: '48px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '200px',
    },
    additionalInfoMetaModal: {
      '& table': {
        '& tr': {
          '& td': {
            backgroundColor: '#f5fbff',
            border: 0,
            borderBottom: '1px solid #a3dcff',
            overflow: 'hidden',
            paddingBottom: 16,
            paddingLeft: 20,
            paddingRight: 0,
            paddingTop: 16,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
          '& th': {
            backgroundColor: '#ccebff',
            border: 0,
            color: '#06517e',
            paddingBottom: 16,
            paddingLeft: 20,
            paddingRight: 0,
            paddingTop: 16,
            textTransform: 'uppercase',
          },
        },
        marginBottom: 10,
        width: '100%',
      },
    },
    bold: {
      fontWeight: 800,
    },
    button: {
      borderRadius: '5px',
    },
    buttonContainer: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            color: theme.palette.secondary.main,
          }
        : {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.text.primary,
          },
    link: {
      textDecoration: 'none',
    },
    modal: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    modalOverflow: {
      overflowY: 'scroll',
    },
    modalPaper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: '5px',
      boxShadow: theme.shadows[5],
      margin: '1rem',
      padding: theme.spacing(2, 4, 3),
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: '5px',
      boxShadow: theme.shadows[5],
      margin: '1rem',
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 auto',
    },
    wrapperModal: {
      [theme.breakpoints.down('sm')]: {
        height: '90vh',
      },
      [theme.breakpoints.up('md')]: {
        height: '70vh',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: '2rem',
      width: '100%',
    },
  }),
);

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export interface documentsTableToolbarProps {
  numSelected: number;
  resetGrid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DocumentsTableToolbar: React.FC<documentsTableToolbarProps> = ({ numSelected, resetGrid }) => {
  const { documentStore, claimsStore } = useStores();
  const classes = useToolbarStyles();
  const query = useQuery();
  const claimNumber = query.get('claimNumber');
  const [openModal, setOpenModal] = React.useState(false);
  const [openDocModal, setOpenDocModal] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [openExtractedMetadataModal, setOpenExtractedMetadataModal] = React.useState(false);
  const [modalExtractedMetadataData, setModalExtractedMetadataData] = React.useState<IExtractedMetadata>();
  const [modalDocumentData, setModalDocumentData] = React.useState<IDocument>();

  const handleOpenModal = () => {
    resetDocumentState(documentStore);
    uploadDocumentState(documentStore, claimsStore);
    setOpenModal(true);
  };

  const handleUpdateModal = () => {
    const allAttachedFiles: string[] = claimsStore.getSelectedDocumentMetadataId();
    const documents: IDocument[] = claimsStore.getIncomingDocuments();
    const selectedDocArray: IDocument[] = documents.filter((d) =>
      allAttachedFiles.includes(d.documentMetadataId.toString()),
    );

    // eslint-disable-next-line no-console
    //console.log('here are the attached ', selectedDocArray[0]);
    updateDocumentState(documentStore, selectedDocArray[0]);
    setOpenUpdateModal(true);
  };

  const handleAdditionalDetails = () => {
    const allAttachedFiles: string[] = claimsStore.getSelectedDocumentMetadataId();
    const documents: IDocument[] = claimsStore.getIncomingDocuments();
    const selectedDocArray: IDocument[] = documents.filter((d) =>
      allAttachedFiles.includes(d.documentMetadataId.toString()),
    );

    // eslint-disable-next-line no-console
    //console.log('here are the attached ', selectedDocArray[0].extractedMetadata);
    if (selectedDocArray[0]) {
      const selectedExtractedMetadata: IExtractedMetadata | undefined =
        selectedDocArray[0]?.extractedMetadata ?? DEFAULT_EXTRACTED_METADATA;
      setModalExtractedMetadataData(selectedExtractedMetadata);
      setModalDocumentData(selectedDocArray[0]);
    }
    setOpenExtractedMetadataModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancelExtractedModal = () => {
    setOpenExtractedMetadataModal(false);
  };

  const handleCloseDetailViewModal = () => {
    setOpenExtractedMetadataModal(false);
  };

  // const moveSelected = () => {
  //   setOpenDocModal(true);
  // };

  const handleCloseUpdateModal = () => {
    setOpenUpdateModal(false);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="h6" component="div">
          {numSelected} documents selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" component="div">
          No documents selected
        </Typography>
      )}

      <div className={classes.buttonContainer}>
        {!numSelected ? (
          <>
            {/* <Button className={classes.button} variant="contained" disabled>
              Move Selected
            </Button> */}

            <Button className={classes.button} variant="contained" disabled>
              Update Selected
            </Button>
            <Button className={classes.button} variant="contained" disabled>
              Additional Details
            </Button>
          </>
        ) : (
          <>
            {/*  <Tooltip title="Move the selected documents to different claim" placement="top-start">
              <Button className={classes.button} variant="contained" onClick={() => moveSelected()}>
                Move Selected
              </Button>
            </Tooltip>*/}
            <Tooltip title="Update the selected documents" placement="top">
              <Button
                className={classes.button}
                variant="contained"
                onClick={handleUpdateModal}
                disabled={numSelected !== 1}
              >
                Update Selected
              </Button>
            </Tooltip>
            <Tooltip title="View Additional Details for selected document" placement="top">
              <Button
                className={classes.button}
                variant="contained"
                onClick={handleAdditionalDetails}
                disabled={numSelected !== 1}
              >
                Additional Details
              </Button>
            </Tooltip>
          </>
        )}
        <Tooltip title="Upload new documents for this claim" placement="top-end">
          <Button className={classes.button} variant="contained" type="button" onClick={handleOpenModal}>
            Upload document
          </Button>
        </Tooltip>
        {numSelected > 0 && openDocModal && (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openDocModal}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openDocModal}>
              <Container maxWidth="lg" className={classes.paper}>
                <Typography variant="h4" component="h4">
                  Move documents for Claim Nr. # {claimNumber}
                </Typography>
                <DocumentFileMoveForm setResetGrid={resetGrid} setOpenDocModal={setOpenDocModal} />
              </Container>
            </Fade>
          </Modal>
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Container maxWidth="lg" className={`${classes.paper} ${classes.modalOverflow}`}>
              <Typography variant="h4" component="h4">
                Upload a New Document for Claim Nr. # {claimNumber}
              </Typography>
              <HorizontalLabelPositionBelowStepper setOpenModal={setOpenModal} />
            </Container>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openUpdateModal}
          onClose={handleCloseUpdateModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openUpdateModal}>
            <Container maxWidth="lg" className={`${classes.paper} ${classes.modalOverflow}`}>
              <Typography variant="h4" component="h4">
                Update document metadata for Claim Nr. # {claimNumber}
              </Typography>
              <UpdateMetaDataStepper setOpenUpdateModal={setOpenUpdateModal} />
            </Container>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openExtractedMetadataModal}
          onClose={handleCloseDetailViewModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openExtractedMetadataModal}>
            <Container maxWidth="lg" className={`${classes.modalPaper} ${classes.modalOverflow}`}>
              <Typography variant="h4" component="h4">
                Detail Information
              </Typography>

              <div className={classes.wrapperModal}>
                <div className={classes.additionalInfoMetaModal}>
                  <table aria-label="document details">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={4}>
                          Document Information
                        </th>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Document Name</td>
                        <td>{modalDocumentData?.documentName}</td>
                        <td className={classes.bold}>Document MetadataId</td>
                        <td>{modalDocumentData?.documentMetadataId}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Upload DateTime</td>
                        <td>{formatedDate(modalDocumentData?.uploadDateTime ?? '')}</td>
                        <td className={classes.bold}>Claim Number</td>
                        <td>{modalDocumentData?.claimNumber}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Comments</td>
                        <td>{modalDocumentData?.comments}</td>
                        <td className={classes.bold}>Document Author</td>
                        <td>{modalDocumentData?.documentAuthor}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Document Type</td>
                        <td>{modalDocumentData?.documentType}</td>
                        <td className={classes.bold}>Mime Type</td>
                        <td>{modalDocumentData?.mimeType}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Document Description</td>
                        <td>{modalDocumentData?.documentDescription}</td>
                        <td className={classes.bold}>Document External Id</td>
                        <td>{modalDocumentData?.documentExternalId}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Document Source</td>
                        <td>{modalDocumentData?.documentSource}</td>
                        <td className={classes.bold}>Feature Id</td>
                        <td>{modalDocumentData?.featureId}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Version</td>
                        <td>{modalDocumentData?.version}</td>
                        <td className={classes.bold}>Document Size</td>
                        <td>{modalDocumentData?.documentSize}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Last Update DateTime</td>
                        <td>{formatedDate(modalDocumentData?.lastUpdateDateTime ?? '')}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </thead>
                  </table>

                  <table aria-label="specific information">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={4}>
                          Specific Information
                        </th>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Cnf Id</td>
                        <td>{modalDocumentData?.specificData.cnfId}</td>
                        <td className={classes.bold}>Scanned By</td>
                        <td>{modalDocumentData?.specificData.scannedBy}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Creation Date</td>
                        <td>{modalDocumentData?.specificData.createDate}</td>
                        <td className={classes.bold}>Correspondence Date</td>
                        <td>{modalDocumentData?.specificData.correspondenceDate}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Department Area</td>
                        <td>{modalDocumentData?.specificData.departmentArea}</td>
                        <td className={classes.bold}>Team</td>
                        <td>{modalDocumentData?.specificData.team}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Ucm Document Source</td>
                        <td>{modalDocumentData?.specificData.ucmDocumentSource}</td>
                        <td className={classes.bold}>Incident Date</td>
                        <td>{modalDocumentData?.specificData.incidentDate}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Region</td>
                        <td>{modalDocumentData?.specificData.region}</td>
                        <td className={classes.bold}>Department</td>
                        <td>{modalDocumentData?.specificData.department}</td>
                      </tr>
                    </thead>
                  </table>

                  <table aria-label="specific data">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={4}>
                          Specific Data
                        </th>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Specific Data</td>
                        <td>
                          <ReactJson
                            name={null}
                            displayDataTypes={false}
                            src={modalDocumentData?.specificData}
                            collapsed={false}
                          />
                        </td>
                      </tr>
                    </thead>
                  </table>

                  <table aria-label="camera details">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={4}>
                          Camera Information
                        </th>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Camera - Make</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.camera?.make}</td>
                        <td className={classes.bold}>Camera - Model</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.camera?.model}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Camera - Lens</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.camera?.lens}</td>
                        <td className={classes.bold}>Camera - Settings - Aperture </td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.camera?.settings?.aperture}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Camera - Settings - Shutter Speed</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.camera?.settings?.shutterSpeed}</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </thead>
                  </table>

                  <table aria-label="datetime details">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={2}>
                          DateTime Information
                        </th>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Original</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.dateTime?.original}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Digitized</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.dateTime?.digitized}</td>
                      </tr>
                    </thead>
                  </table>

                  <table aria-label="image details">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={2}>
                          Image Information
                        </th>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Length</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.image?.length}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Width</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.image?.width}</td>
                      </tr>
                    </thead>
                  </table>

                  <table aria-label="gps details">
                    <thead>
                      <tr>
                        <th scope="col" colSpan={4}>
                          GPS Information
                        </th>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Latitude - Value</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.latitude.value}</td>
                        <td className={classes.bold}>Latitude - Ref</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.latitude.ref}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Longitude - Value</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.longitude.value}</td>
                        <td className={classes.bold}>Longitude - Ref</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.longitude.ref}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Altitude - Value</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.altitude.value}</td>
                        <td className={classes.bold}>Altitude - Ref</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.altitude.ref}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Speed - Value</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.speed.value}</td>
                        <td className={classes.bold}>Speed - Ref</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.speed.ref}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Image Direction - Value</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.imgDirection.value}</td>
                        <td className={classes.bold}>Image Direction - Ref</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.imgDirection.ref}</td>
                      </tr>
                      <tr>
                        <td className={classes.bold}>Destination Bearing - Value</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.destBearing.value}</td>
                        <td className={classes.bold}>Destination Bearing - Ref</td>
                        <td>{modalExtractedMetadataData?.exifMetadata?.gps?.destBearing.ref}</td>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className={classes.flex}>
                  <div>
                    <Button variant="contained" onClick={handleCancelExtractedModal} className={classes.actionButton}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Container>
          </Fade>
        </Modal>
      </div>
    </Toolbar>
  );
};
