import { Backdrop, Button, CircularProgress } from '@esure-cloud/react-components';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import React from 'react';
import { storageManService } from '../../service/storageman';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useStores } from '../../service/state/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      color: '#fff',
      zIndex: theme.zIndex.drawer + 1,
    },
    cellButton: {
      borderRadius: '5px',
      fontSize: '0.8rem',
      lineHeight: '1rem',
      width: 'max-content',
    },
  }),
);

export const FileDownloadButton: React.FC = () => {
  const classes = useStyles();
  const { journeyStore } = useStores();
  const [open, setOpen] = React.useState(false);

  const downloadFileWithBackdrop = async (documentMetadataId: string) => {
    setOpen(!open);
    await downloadFile(documentMetadataId);
    setOpen(false);
  };
  const downloadFile = async (documentMetadataId: string) => {
    const documentResponce = await storageManService.retrieveDocument(documentMetadataId);
    const [first] = documentResponce.data.results;
    const {
      documentLocation,
      documentName,
      mimeType,
    }: { documentLocation: string; documentName: string; mimeType: string } = first;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.URL = window.URL || window.webkitURL;

    const xhr = new XMLHttpRequest();
    const a = document.createElement('a');

    xhr.open('GET', documentLocation, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      const file = new Blob([xhr.response], { type: mimeType });
      a.href = window.URL.createObjectURL(file);
      a.download = documentName;
      a.click();
    };
    xhr.send();
  };
  return (
    <>
      <Button
        endIcon={<ArrowDownward />}
        className={classes.cellButton}
        variant="contained"
        onClick={() => downloadFileWithBackdrop(journeyStore.currentDocumentMetadataId.toString())}
      >
        Download
      </Button>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
