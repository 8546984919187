import React, { useState } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Step, StepLabel, Stepper } from '@material-ui/core';

import { DocumentMetadataUploadOrUpdateForm } from './documentMetadataUploadOrUpdateForm';
import { useStores } from '../../service/state/store';
import { DocumentUploadConfirmation } from './documentUploadConfirmation';
import { IPatchDocMetadata, storageManService } from '../../service/storageman';
import { resetDocumentState } from './documentUploadService';
import { useQuery } from '../../service/network/useQueryString';
import { Alert } from '@esure-cloud/react-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    aa: {
      height: '100%',
    },
    actionButton: {
      borderRadius: '8px',
      height: '48px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '200px',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.main,
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    instructions: {
      margin: '2rem 0 auto',
    },
    root: {
      [theme.breakpoints.down('sm')]: {
        height: '90vh',
      },
      [theme.breakpoints.up('md')]: {
        height: '70vh',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: '2rem',
      width: '100%',
    },
    step: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  }),
);

function getSteps() {
  return ['Step 1 - Update document', 'Step 2 - “Document Metadata'];
}

interface ModalProps {
  setOpenUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateMetaDataStepper: React.FC<ModalProps> = ({ setOpenUpdateModal }) => {
  const { claimsStore, documentStore } = useStores();
  const classes = useStyles();
  const steps = getSteps();
  const query = useQuery();
  const claimNumber: string = query.get('claimNumber') ?? '';

  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const [formValid, setFormValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const [docTypeValid, setDocTypeValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    resetDocumentState(documentStore);
  };

  const CancelAndExit = () => {
    handleReset();
    setOpenUpdateModal(false);
  };

  const handleClose = () => {
    setOpenUpdateModal(false);
    storageManService.getSearchDocument(claimNumber).then((response) => {
      claimsStore.setIncomingDocuments(response.data.results);
    });
  };

  const handleCancel = () => {
    documentStore.getDocumentName() !== ''
      ? window.confirm('Are you sure? you will lose your progress') && CancelAndExit()
      : setOpenUpdateModal(false);
  };

  const handleError = () => {
    setShowError(true);
  };

  const ErrorMessages = () => {
    return (
      <div>
        {!docTypeValid && <div className={classes.error}>Document type is required</div>}
        {!nameValid && <div className={classes.error}>Document name is required</div>}
      </div>
    );
  };

  const handleSubmit = () => {
    setLoading(true);

    // 1. storageman update metadata
    const docUpdate: IPatchDocMetadata = {
      comments: documentStore.getDocumentComments(),
      documentDescription: documentStore.getDocumentDescription(),
      documentName: documentStore.getDocumentName(),
      documentType: documentStore.getDocumentType(),
      featureId: documentStore.getFeatureId() ?? '',
      version: documentStore.getVersion(),
    };

    formValid
      ? storageManService
          .updateMetadata(docUpdate, documentStore.getDocumentMetadataId())
          .then(() => {
            setLoading(false);
            setError('');
            handleNext();
          })
          .catch(() => {
            setError('Update failed');
            setLoading(false);
          })
      : handleError();
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {error && (
        <div>
          <Alert severity="error"> {error} </Alert>
        </div>
      )}
      <div className={classes.aa}>
        {activeStep === steps.length - 1 ? (
          <div className={classes.step}>
            <DocumentUploadConfirmation loading={loading} />
            <div>
              <Button variant="contained" onClick={handleClose} className={classes.actionButton}>
                Close
              </Button>
            </div>
          </div>
        ) : (
          <div className={classes.step}>
            {activeStep === 0 && (
              <div className={classes.instructions}>
                {showError && <ErrorMessages />}
                <DocumentMetadataUploadOrUpdateForm
                  docTypeValid={setDocTypeValid}
                  nameValid={setNameValid}
                  setFormValid={setFormValid}
                />
              </div>
            )}
            <div className={classes.flex}>
              <div>
                {activeStep === 0 && (
                  <Button variant="contained" className={classes.actionButton} onClick={handleSubmit}>
                    Update Metadata
                  </Button>
                )}
              </div>
              <div>
                <Button onClick={handleCancel} color="primary" className={classes.actionButton}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
