import React, { useState } from 'react';
import styled from 'styled-components';
import { useStores } from '../../service/state/store';

import { IUpdateClaim, storageManService } from '../../service/storageman';

interface ModalProps {
  setOpenDocModal: React.Dispatch<React.SetStateAction<boolean>>;
  setResetGrid: React.Dispatch<React.SetStateAction<boolean>>;
}

const Container = styled.div`
  position: relative;
`;

const Button = styled.button`
  margin-right: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const ListFiles = styled.ul``;

const ListFileItem = styled.li``;

const Label = styled.span<{ margin: boolean }>`
  display: block;
  margin-bottom: ${(props) => (props.margin ? '20px' : '0')};
`;

const FormWrap = styled.div`
  margin-bottom: 20px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const ErrorWrap = styled.div`
  color: red;
  margin: 20px 0;
`;

const ConfirmationWrap = styled.div``;

const CTAWrap = styled.div`
  margin-top: 20px;
`;

export const DocumentFileMoveForm: React.FC<ModalProps> = ({ setResetGrid, setOpenDocModal }) => {
  const { claimsStore, documentStore } = useStores();

  const [newClaimNo, setNewClaimNo] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  //Store values
  const selectedDocuments = documentStore.getDocumentName();
  const selectedDocID = +claimsStore.getSelectedDocumentMetadataId();

  const handleSubmit = () => {
    const updateClaim: IUpdateClaim = {
      claimNumber: +newClaimNo,
      version: documentStore.getVersion(),
    };

    storageManService
      .updateDocumentToClaim(updateClaim, selectedDocID)
      .then(() => {
        //UI Updates
        setError(false);
        setSubmitted(true);
        setSuccess(true);

        //Auto close modal after confirmation
        setTimeout(() => {
          setOpenDocModal(false);
        }, 10000);
      })
      .catch(() => {
        //console.log('error', error);

        //UI Updates
        setError(true);
        setSubmitted(true);
        setSuccess(false);
      });
  };

  const closeModal = () => {
    setOpenDocModal(false);
  };
  const cancelMove = () => {
    if (window.confirm('Are you sure you want to cancel? You will lose all your progress.')) {
      setOpenDocModal(false);
      setResetGrid(true);
    }
  };

  const newClaimNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewClaimNo(e.target.value);
  };

  const ErrorMessage = () => {
    return (
      <ErrorWrap>
        <span>Sorry something went wrong, please try again</span>
      </ErrorWrap>
    );
  };

  const SuccessMessage = () => {
    return (
      <ConfirmationWrap>
        <Label margin={false}>Thank you, your documents have been moved.</Label>
      </ConfirmationWrap>
    );
  };

  return (
    <Container>
      {/* UI UPDATE */}
      {error && (
        <Container>
          <ErrorMessage />
          <Label margin={true}>Selected files:</Label>
          <ListFiles>
            <ListFileItem>{selectedDocuments}</ListFileItem>
          </ListFiles>
          <FormWrap>
            <Label margin={false}>Please provide the new claim number to move selected documents to</Label>
            <input type="number" onChange={(e) => newClaimNumber(e)} />
          </FormWrap>
          <CTAWrap>
            <Button onClick={() => closeModal()}>Back</Button>
            <Button onClick={() => cancelMove()}>Cancel</Button>
            <Button onClick={() => handleSubmit}>Submit</Button>
          </CTAWrap>
        </Container>
      )}
      {!submitted && (
        <div>
          <Label margin={true}>Selected files:</Label>
          <ListFiles>
            <ListFileItem>{selectedDocuments}</ListFileItem>
          </ListFiles>
          <FormWrap>
            <Label margin={false}>Please provide the new claim number to move selected documents to</Label>
            <input type="number" onChange={(e) => newClaimNumber(e)} />
          </FormWrap>
          <CTAWrap>
            <Button onClick={() => closeModal()}>Back</Button>
            <Button onClick={() => cancelMove()}>Cancel</Button>
            <Button onClick={() => handleSubmit()}>Submit</Button>
          </CTAWrap>
        </div>
      )}
      {success && <SuccessMessage />}
    </Container>
  );
};
