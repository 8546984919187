import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import { Alert, Button, Divider, Paper, Typography } from '@esure-cloud/react-components';
import { observer } from 'mobx-react-lite';
import { IDocument, storageManService } from '../../service/storageman';
import { useStores } from '../../service/state/store';
import { AttachmentGrid } from '../../component/attachment/attachmentGrid';
import { a11yProps, TabPanel } from '../../component/tabPanel/tabPanel';
import { useQuery } from '../../service/network';
import { ROUTE_TEMPLATE_STEP } from '../../service/constant';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
  },
}));

export const Attachment: React.FC = observer(() => {
  const { claimsStore } = useStores();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const query = useQuery();
  const history = useHistory();

  const claimNumber = query.get('claimNumber') ?? '';

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (claimNumber) {
      storageManService.getSearchDocument(claimNumber).then((response) => {
        claimsStore.setIncomingDocuments(response.data.results);
        setDocuments(response.data.results);
      });
    }
  }, [claimNumber, claimsStore]);

  const routeChange = (route: string) => {
    history.push({ pathname: route, search: '?claimNumber=' + claimNumber });
  };

  const onEditor = () => {
    routeChange(ROUTE_TEMPLATE_STEP.EDITOR);
  };

  return (
    <div className={classes.root}>
      <Paper square>
        <Tabs value={value} onChange={() => handleChange(value)}>
          <Tab label="Attachment" {...a11yProps(0)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Typography variant="h4" component="h4">
          Add attachments : Claim Number # {claimNumber}
        </Typography>
        <Divider style={{ margin: '1rem 0' }} />
        {documents.length === 0 ? (
          <Alert
            variant="outlined"
            severity="info"
            action={
              <Button color="secondary" size="medium" variant="outlined" onClick={() => onEditor()}>
                Editor
              </Button>
            }
          >
            No documents to display
          </Alert>
        ) : (
          <AttachmentGrid />
        )}
      </TabPanel>
    </div>
  );
});
