import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@esure-cloud/react-components';
import { Table } from './table';
import {
  claimsService,
  IClaimantV2,
  ICreateDraftGroupRequest,
  ICreateDraftRequest,
  IDamageV2,
  ITemplateGroup,
} from '../service/claims';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_TEMPLATE_STEP } from '../service/constant';
import { useStores } from '../service/state/store';
import { useOktaAuth } from '@okta/okta-react';
import { isMobilePhoneNumberValid } from '../service/util/utils';
import { DEFAULT_PARAMETERS } from '../service/state/mock/stateMock';

interface IRole {
  code: string;
  displayValue: string;
  parties: IParty[];
}

export interface IParty {
  bounced?: boolean;
  displayValue: string;
  email: string | null;
  oid: string;
  partyType: string;
  phone: string | null;
}

interface CustomErrorMessage {
  errorMessage: string[];
  hasError: boolean;
}

const useStyles = makeStyles(() => ({
  cellSpan: {
    paddingRight: 25,
  },
}));

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const mapCreateDraftGroupToCrateDraft = (
  templateIndex: number,
  createDraftGrouped: ICreateDraftGroupRequest,
): ICreateDraftRequest => ({
  parameters: createDraftGrouped.parameters,
  template: createDraftGrouped.templateGroup.templates[templateIndex],
});

const defaultDraftGroup: ICreateDraftGroupRequest = {
  parameters: DEFAULT_PARAMETERS,
  templateGroup: { groupDescription: '', groupName: '', templates: [] },
};

export const Search: React.FC = () => {
  const { interfaceStore, journeyStore, claimsStore } = useStores();
  claimsStore.resetEditedTemplateResponceList();
  journeyStore.setPage('SEARCH');

  const { authState } = useOktaAuth();

  const [damageV2, setDamageV2] = useState('');
  const [fullDamageV2, setFullDamageV2] = useState<IDamageV2>();
  const [damagesV2, setDamagesV2] = useState<IDamageV2[]>([]);

  const [claimantV2, setClaimantV2] = useState('');
  const [fullClaimantV2, setFullClaimantV2] = useState<IClaimantV2>();
  const [claimantsV2, setClaimantsV2] = useState<IClaimantV2[]>([]);

  const [role, setRole] = useState('');
  const [fullRole, setFullRole] = useState<IRole>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [recipient, setRecipient] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [recipientPhone, setRecipientPhone] = useState('');
  const [recipientEmailError, setRecipientEmailError] = useState<CustomErrorMessage>();
  const [recipientPhoneError, setRecipientPhoneError] = useState<CustomErrorMessage>();
  const [claimantRequiredError, setClaimantRequiredError] = useState<CustomErrorMessage>();
  const [fullRecipient, setFullRecipient] = useState<IParty>();
  const [recipients, setRecipients] = useState<IParty[]>([]);

  const [channel, setChannel] = useState('');
  const [templates, setTemplates] = useState<ITemplateGroup[]>([]);
  const query = useQuery();
  const claimNumber = query.get('claimNumber') ?? '';
  const [inputAutocompleteValue, setAutocompleteInputValue] = useState('');
  const [templateGroup, setTemplateGroupValue] = useState<ITemplateGroup | null>();

  const [createDraftGroupedToSend, setCreateDraftGroupedToSend] = useState<ICreateDraftGroupRequest>(defaultDraftGroup);

  interfaceStore.docGenSearch.setActiveStepIndex(0);

  const optionalClaimant: IClaimantV2 = { displayValue: '', featureId: 0, featureNumber: '0', refId: '0' };
  const claimantRequiredErrorMsg = 'Claimant is required.';
  const emailBlankError = 'Recipient email address is missing.';
  const phoneBlankError = 'Recipient phone is missing.';
  const phoneNonMobileUkError = 'Recipient phone is not a UK mobile number.';
  const emailBouncedError =
    "The recipient's email address has bounced previously. Please choose an alternative email address.";
  const validationEmailChannel = 'EMAIL';
  const validationPhoneChannel = 'SMS';

  const history = useHistory();

  const routeChange = (route: string) => {
    history.push({ pathname: route, search: '?claimNumber=' + claimNumber });
  };

  const classes = useStyles();

  const onCreateDraftDocumentCreate = (
    claimId: string | null,
    templateIndex: number,
    createDraftGrouped: ICreateDraftGroupRequest,
  ) => {
    if (claimId == null) {
      claimId = '';
    }
    interfaceStore.docGenSearch.setClaimNumber(claimId);
    interfaceStore.docGenSearch.setSelectedChannel(channel);
    interfaceStore.partyFull.setPartyFull(fullRecipient);

    claimsStore.setGroupTemplate(createDraftGrouped.templateGroup);
    claimsStore.setSelectedParameters(createDraftGrouped.parameters);
    claimsStore.setSelectedTemplate(createDraftGrouped.templateGroup.templates[templateIndex]);
    claimsStore.setNumberOfTemplates(createDraftGrouped.templateGroup.templates.length);
    claimsStore.setCurrentTemplate(templateIndex + 1);

    const createDraft: ICreateDraftRequest = mapCreateDraftGroupToCrateDraft(templateIndex, createDraftGrouped);

    if (createDraft.parameters.featureId !== undefined && createDraft.parameters.featureId === 0) {
      delete createDraft.parameters.featureId;
    }
    if (createDraft.parameters.featureNumber !== undefined && createDraft.parameters.featureNumber === '0') {
      delete createDraft.parameters.featureNumber;
    }

    claimsService.createDraft(claimId, createDraft).then((response) => {
      response.data.templatePaginationId = templateIndex;
      claimsStore.setSelectedTemplateResponse(response.data);
      routeChange(ROUTE_TEMPLATE_STEP.EDITOR);
    });
  };

  const onReset = () => {
    setDamageV2('');
    setFullDamageV2(undefined);

    setClaimantV2('');
    setFullClaimantV2(undefined);

    setRole('');
    setFullRole(undefined);
    setRoles([]);

    setRecipient('');
    setRecipientEmail('');
    setRecipientPhone('');
    setFullRecipient(undefined);
    setRecipients([]);

    setChannel('');
    setTemplates([]);

    setAutocompleteInputValue('');
    setTemplateGroupValue(null);
    setRecipientEmailError({
      errorMessage: [],
      hasError: false,
    });
    setRecipientPhoneError({
      errorMessage: [],
      hasError: false,
    });
    setClaimantRequiredError({
      errorMessage: [],
      hasError: false,
    });

    setCreateDraftGroupedToSend(defaultDraftGroup);
    claimsStore.setDocumentMetadataId([]);
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const _email: string = authState.accessToken?.claims.email ?? '';
      const _name = authState.accessToken?.claims.Name ?? '';
      const _firstName = authState.accessToken?.claims.firstName ?? '';
      const _lastName = authState.accessToken?.claims.lastName ?? '';
      claimsStore.setOktaEmail(_email);
      claimsStore.setOktaName(_name.toString());
      claimsStore.setOktaFirstName(_firstName.toString());
      claimsStore.setOktaLastName(_lastName.toString());
    }
  }, [authState, claimsStore]);

  useEffect(() => {
    if (claimNumber) {
      claimsService.getDamagesV2(claimNumber).then((response) => setDamagesV2(response.data));
      claimsStore.setDocumentMetadataId([]);
    }
  }, [claimNumber, claimsStore]);

  useEffect(() => {
    if (damageV2 && claimNumber) {
      claimsService.getClaimantsV2(claimNumber, damageV2).then((response) => setClaimantsV2(response.data));
      claimsStore.setDocumentMetadataId([]);
    }
  }, [damageV2, claimNumber, claimsStore]);

  useEffect(() => {
    if (claimNumber && fullDamageV2) {
      claimsService.getParties(claimNumber, fullDamageV2.damageNumber).then((response) => setRoles(response.data));
    }
  }, [claimNumber, fullDamageV2]);

  useEffect(() => {
    if (role) {
      const chosenRole = roles.find((roleItem) => roleItem.code === role);
      if (chosenRole) {
        setFullRole(chosenRole);
      }

      const recipients = chosenRole?.parties.map((party) => party) ?? [];

      setRecipients(recipients);
    }
  }, [roles, role]);

  useEffect(() => {
    const customError: CustomErrorMessage = {
      errorMessage: [],
      hasError: false,
    };
    if (claimantsV2.length > 0 && fullClaimantV2?.refId && fullClaimantV2.refId !== '0') {
      customError.hasError = false;
      customError.errorMessage = [];

      setClaimantRequiredError(customError);
    }

    if (fullRole && claimantsV2.length > 0 && claimantV2 === '') {
      customError.hasError = true;
      customError.errorMessage.push(claimantRequiredErrorMsg);

      setClaimantRequiredError(customError);
    }
  }, [fullRole, claimantsV2, claimantV2, fullClaimantV2]);

  useEffect(() => {
    if (recipient && role) {
      const chosenRecipient = recipients.find((party) => party.oid === recipient);
      if (chosenRecipient) {
        setFullRecipient(chosenRecipient);
        const em = chosenRecipient.email ?? '';
        setRecipientEmail(em);
        const phone = chosenRecipient.phone ?? '';
        setRecipientPhone(phone);
      }

      if (chosenRecipient && channel === validationEmailChannel) {
        const customError: CustomErrorMessage = {
          errorMessage: [],
          hasError: false,
        };
        if (!chosenRecipient.email) {
          customError.hasError = true;
          customError.errorMessage.push(emailBlankError);
        }

        if (chosenRecipient.bounced) {
          customError.hasError = true;
          customError.errorMessage.push(emailBouncedError);
        }
        setRecipientEmailError(customError);
      } else {
        setRecipientEmailError({
          errorMessage: [],
          hasError: false,
        });
      }

      if (chosenRecipient && channel === validationPhoneChannel) {
        const customError: CustomErrorMessage = {
          errorMessage: [],
          hasError: false,
        };
        if (!chosenRecipient.phone) {
          customError.hasError = true;
          customError.errorMessage.push(phoneBlankError);
        } else if (!isMobilePhoneNumberValid(chosenRecipient.phone)) {
          customError.hasError = true;
          customError.errorMessage.push(phoneNonMobileUkError);
        }

        setRecipientPhoneError(customError);
      } else {
        setRecipientPhoneError({
          errorMessage: [],
          hasError: false,
        });
      }

      if (claimNumber && chosenRecipient && channel) {
        claimsService
          .getTemplate(claimNumber, channel, role, chosenRecipient.partyType)
          .then((response) => setTemplates(response.data));
      }
    } else {
      setTemplates([]);
    }
  }, [claimNumber, role, recipients, recipient, channel]);

  // fullClaimantV2 optional with v2
  useEffect(() => {
    if (fullClaimantV2 && fullDamageV2 && fullRole && fullRecipient && channel && templateGroup) {
      const groupedDraft = mapToCreateDraftGrouped(
        fullClaimantV2,
        fullDamageV2,
        fullRole,
        fullRecipient,
        channel,
        templateGroup,
      );
      setCreateDraftGroupedToSend(groupedDraft);
    }
  }, [fullClaimantV2, fullDamageV2, fullRole, fullRecipient, channel, templateGroup]);

  const mapToCreateDraftGrouped = (
    claimant: IClaimantV2,
    damage: IDamageV2,
    role: IRole,
    recepient: IParty,
    channel: string,
    templateGroup: ITemplateGroup,
  ) => {
    const createDraftGroup: ICreateDraftGroupRequest = {
      parameters: {
        damageNumber: damage.damageNumber,
        featureId: claimant.featureId,
        featureNumber: claimant.featureNumber,
        partyId: recepient.oid,
      },
      templateGroup,
    };
    return createDraftGroup;
  };

  const handleClaimantV2Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setClaimantV2(event.target.value as string);

    const selectedClaimantV2: IClaimantV2 | undefined = claimantsV2.find((i) => i.refId === event.target.value);
    setFullClaimantV2(selectedClaimantV2);

    // Change of claimant should not modify role - claimant is optional on v2
    // setRole('');
    // setFullRole(undefined);
    // setRoles([]);

    // Change of claimant should not delete the recipient - claimant is optional on v2
    // setRecipient('');
    // setRecipientEmail('');
    // setRecipientPhone('');
    // setFullRecipient(undefined);
    // setRecipients([]);

    setChannel('');
    setTemplates([]);

    setAutocompleteInputValue('');
    setTemplateGroupValue(null);
    setRecipientEmailError({
      errorMessage: [],
      hasError: false,
    });
    setRecipientPhoneError({
      errorMessage: [],
      hasError: false,
    });
    setClaimantRequiredError({
      errorMessage: [],
      hasError: false,
    });

    setCreateDraftGroupedToSend(defaultDraftGroup);
  };

  const handleDamageV2Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDamageV2(event.target.value as string);

    const selectedDamageV2: IDamageV2 | undefined = damagesV2.find((i) => i.damageNumber === event.target.value);
    setFullDamageV2(selectedDamageV2);

    setClaimantV2('');
    setFullClaimantV2(optionalClaimant);

    setRole('');
    setFullRole(undefined);

    setRecipient('');
    setRecipientEmail('');
    setRecipientPhone('');
    setFullRecipient(undefined);
    setRecipients([]);

    setChannel('');
    setTemplateGroupValue(null);
    setAutocompleteInputValue('');

    setRecipientEmailError({
      errorMessage: [],
      hasError: false,
    });
    setRecipientPhoneError({
      errorMessage: [],
      hasError: false,
    });
    setClaimantRequiredError({
      errorMessage: [],
      hasError: false,
    });

    setCreateDraftGroupedToSend(defaultDraftGroup);
  };

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as string);

    setTemplateGroupValue(null);
    setAutocompleteInputValue('');

    setRecipient('');
    setRecipientEmail('');
    setRecipientPhone('');
    setFullRecipient(undefined);

    setCreateDraftGroupedToSend(defaultDraftGroup);
  };

  const handleRecipientChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRecipient(event.target.value as string);
    setTemplateGroupValue(null);
    setAutocompleteInputValue('');

    setCreateDraftGroupedToSend(defaultDraftGroup);
  };

  const handleChannelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChannel(event.target.value as string);
    setTemplateGroupValue(null);
    setAutocompleteInputValue('');

    setCreateDraftGroupedToSend(defaultDraftGroup);
  };

  return (
    <>
      <Container>
        <Box p={2}>
          <FormControl fullWidth>
            <InputLabel id="damageV2">Damage</InputLabel>
            <Select labelId="damageV2" id="damageV2" value={damageV2} label="damageV2" onChange={handleDamageV2Change}>
              {damagesV2.length &&
                damagesV2.map((damageV2: IDamageV2) => (
                  <MenuItem key={damageV2.damageNumber.toString()} value={damageV2.damageNumber.toString()}>
                    {damageV2.displayValue}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth error={claimantRequiredError?.hasError}>
            <InputLabel id="claimantV2">Claimant</InputLabel>
            <Select
              labelId="claimantV2"
              id="claimantV2"
              value={claimantV2}
              label="claimantV2"
              onChange={handleClaimantV2Change}
            >
              {claimantsV2.length &&
                claimantsV2.map((claimantV2: IClaimantV2) => (
                  <MenuItem key={claimantV2.refId} value={claimantV2.refId}>
                    {claimantV2.displayValue}
                  </MenuItem>
                ))}
            </Select>
            {claimantRequiredError?.errorMessage.map((message: string) => (
              <FormHelperText key={message}>{message}</FormHelperText>
            ))}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="role">Role</InputLabel>
            <Select labelId="role" id="role" value={role} label="role" onChange={handleRoleChange}>
              {roles.length &&
                roles.map((role: IRole) => (
                  <MenuItem key={role.code} value={role.code}>
                    {role.displayValue}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="recipient">Recipient</InputLabel>
            <Select
              labelId="recipient"
              id="recipient"
              value={recipient}
              label="recipient"
              onChange={handleRecipientChange}
            >
              {recipients.length &&
                recipients.map((recipient: IParty) => (
                  <MenuItem key={recipient.oid} value={recipient.oid}>
                    {recipient.displayValue}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Tooltip title={`Only active for channel ${validationEmailChannel}`}>
            <span>
              <FormControl fullWidth disabled error={recipientEmailError?.hasError} variant="standard">
                <InputLabel htmlFor="recipient-email">Recipient Email</InputLabel>
                <Input id="recipient-email" value={recipientEmail ? recipientEmail : ''} />
                {recipientEmailError?.errorMessage.map((message: string) => (
                  <FormHelperText key={message}>{message}</FormHelperText>
                ))}
              </FormControl>
            </span>
          </Tooltip>
          <Tooltip title={`Only active for channel ${validationPhoneChannel}`}>
            <span>
              <FormControl fullWidth disabled error={recipientPhoneError?.hasError} variant="standard">
                <InputLabel htmlFor="recipient-phone">Recipient Phone</InputLabel>
                <Input id="recipient-phone" value={recipientPhone ? recipientPhone : ''} />
                {recipientPhoneError?.errorMessage.map((message: string) => (
                  <FormHelperText key={message}>{message}</FormHelperText>
                ))}
              </FormControl>
            </span>
          </Tooltip>
          <FormControl fullWidth>
            <InputLabel id="communication-channel">Communication channel</InputLabel>
            <Select
              labelId="communication-channel"
              id="communication-channel"
              value={channel}
              label="channel"
              onChange={handleChannelChange}
            >
              <MenuItem value={'EMAIL'}>Email</MenuItem>
              <MenuItem value={'LETTER'}>Letter</MenuItem>
              <MenuItem value={'SMS'}>SMS</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={templates}
            noOptionsText="No results for given Search Criteria"
            open={true}
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            onChange={(event: any, newValue: ITemplateGroup | null) => {
              setTemplateGroupValue(newValue);
            }}
            inputValue={inputAutocompleteValue}
            onInputChange={(event, newInputValue) => {
              setAutocompleteInputValue(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} label="Template" />}
            getOptionLabel={(option) => option.groupName}
            renderOption={(option, { selected }) => (
              <Grid container spacing={2} key={option.groupName}>
                <Grid item xs={1}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                </Grid>
                <Grid item xs={5}>
                  <span className={classes.cellSpan}>{option.groupName}</span>
                </Grid>
                <Grid item xs={6}>
                  <span>{option.groupDescription}</span>
                </Grid>
              </Grid>
            )}
            PaperComponent={Table}
          />
        </Box>
      </Container>
      <Box style={{ zIndex: 99999 }}>
        <Button
          disabled={
            (recipientEmailError?.hasError ?? false) ||
            (recipientPhoneError?.hasError ?? false) ||
            (claimantsV2.length > 0 && claimantV2 === '') ||
            createDraftGroupedToSend.templateGroup.templates.length === 0
          }
          onClick={() => onCreateDraftDocumentCreate(claimNumber, 0, createDraftGroupedToSend)}
        >
          Create Document
        </Button>
        <Button onClick={() => onReset()}>Reset</Button>
      </Box>
    </>
  );
};
