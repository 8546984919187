import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TextField, MenuItem, FormControl, Select, Button, Paper, Tooltip, Grid } from '@esure-cloud/react-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      borderRadius: '8px',
      height: '48px',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '200px',
    },
    button: {
      borderRadius: '8px',
      height: '100%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonsGrid: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: '3rem',
      width: '100%',
    },
    grid: {
      display: 'flex',
      width: '100%',
    },
    hidden: {
      display: 'none',
    },
    inputLabel: {
      alignItems: 'center',
      backgroundColor: '#cfe8fc',
      display: 'flex',
      padding: '0 1rem',
      width: '50%',
    },
    optionField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 250,
    },
    paper: {
      alignItems: 'center',
      backgroundColor: '#e0e0e0',
      color: theme.palette.text.secondary,
      display: 'flex',
      flexGrow: 1,
      padding: '12px',
    },
    root: {
      flexGrow: 1,
      marginTop: '3rem',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }),
);

export const DocumentsUploadForm: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState('Fill in');
  const [path, setPath] = React.useState('No file selected');
  const [name, setName] = React.useState('');
  const [type, setType] = React.useState('');

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };
  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPath(event.target.value.split(`\\fakepath\\`)[1]);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item className={classes.grid} xs={12} md={6}>
          <Paper className={classes.paper}>Feature Name</Paper>
          <FormControl>
            <Select
              required
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={name}
              onChange={handleNameChange}
              className={classes.optionField}
              variant="outlined"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item className={classes.grid} xs={12} md={6}>
          <Paper className={classes.paper}>Document Type</Paper>
          <FormControl>
            <Select
              required
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={type}
              onChange={handleTypeChange}
              className={classes.optionField}
              variant="outlined"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item className={classes.grid} xs={12} md={6}>
          <Paper className={classes.paper}>Description</Paper>
          <TextField
            required
            id="outlined-multiline-flexible"
            value={value}
            onChange={handleChange}
            className={classes.textField}
            variant="outlined"
          />
        </Grid>

        <Grid item className={classes.grid} xs={12} md={6}>
          <Paper className={classes.paper}>Received Date</Paper>
          <TextField
            id="date"
            type="date"
            defaultValue="2017-05-24"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item className={classes.grid} xs={12}>
          <Paper className={classes.paper}>{path}</Paper>
          <input
            color="primary"
            type="file"
            onChange={onInputChange}
            id="icon-button-file"
            className={classes.hidden}
          />
          <Tooltip title="Select a document from your files" placement="bottom">
            <label htmlFor="icon-button-file">
              <Button className={classes.button} variant="outlined" component="span">
                Browse
              </Button>
            </label>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid className={classes.buttonsGrid}>
        <Button className={classes.actionButton} variant="contained">
          Submit
        </Button>

        <Button className={classes.actionButton} variant="outlined">
          Cancel
        </Button>
      </Grid>
    </form>
  );
};
