import { Box, Grid, makeStyles, Paper } from '@esure-cloud/react-components';

const useStyles = makeStyles(() => ({
  paper: {
    '& ul li': {
      padding: 0,
    },
  },
}));

export const Table: React.FC = (props) => {
  const classes = useStyles();
  return (
    <>
      <Box marginTop={8}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h3>Template name</h3>
          </Grid>
          <Grid item xs={6}>
            <h3>Description</h3>
          </Grid>
        </Grid>
      </Box>
      <Paper {...props} className={classes.paper}>
        {props.children}
      </Paper>
    </>
  );
};
