import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, Grid } from '@esure-cloud/react-components';
import { useStores } from '../../service/state/store';

import { routeListSearchStep } from '../../service/routeConfig';
import { DocGenBreadcrumbRow } from '../../component/search/breadcrumbRow';

export const Template: React.FC = observer(() => {
  const { interfaceStore } = useStores();

  return (
    <Container maxWidth={interfaceStore.docGenSearch.activeStepIndex === 0 ? 'xl' : 'lg'}>
      <Grid container justifyContent="space-between" spacing={4}>
        <Grid container direction="column" item xs>
          <Grid id="breadcrumb-container" item>
            <Container maxWidth="lg">
              <DocGenBreadcrumbRow />
            </Container>
          </Grid>
          <Grid item>
            <Switch>
              {routeListSearchStep.map((r) => (
                <Route key={`rlrs${r.path}`} {...r} />
              ))}
              <Redirect to={interfaceStore.docGenSearch.transformIndexToPath(0)} />
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
});
