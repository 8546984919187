import { Grid, Typography } from '@esure-cloud/react-components';
import Pagination from '@material-ui/lab/Pagination';
import { useStores } from '../service/state/store';

export interface ITemplatePaginator {
  onClick: (curTmplNum: number) => void;
}

export const TemplatePaginator: React.FC<ITemplatePaginator> = ({ onClick }) => {
  const { claimsStore } = useStores();
  const currentTemplateNum = claimsStore.currentTemplate;
  const numberOfTemplatesNum = claimsStore.numberOfTemplates;

  const onTemplateNumberChange = (event: unknown, value: number) => {
    onClick(value);
  };

  return (
    <Grid container justifyContent="flex-end">
      {claimsStore.numberOfTemplates > 1 && (
        <>
          <Typography>
            Template: {currentTemplateNum}/{numberOfTemplatesNum}
          </Typography>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={claimsStore.numberOfTemplates}
            page={currentTemplateNum}
            onChange={onTemplateNumberChange}
            color="secondary"
          />
        </>
      )}
    </Grid>
  );
};
