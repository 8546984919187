import { Container, makeStyles } from '@esure-cloud/react-components';
import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Header } from '../component/common';
import { routeList } from '../service/routeConfig';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import config from '../service/config';
import { ROUTE_TEMPLATE_STEP } from '../service/constant';
import { version as AppVersion } from '../../package.json';

const useStyles = makeStyles((theme) => ({
  appVersionStyle: {
    bottom: 0,
    left: 0,
    padding: '1em',
    position: 'absolute',
  },
  container: {
    flex: 1,
    //marginBottom: 300,
  },

  headerOffset: theme.mixins.toolbar,
}));

const CLIENT_ID = config.okta.okta_clientId;
const ISSUER = config.okta.okta_issuer;
const REDIRECT_URI = config.okta.okta_redirectUri;

const oktaAuth = new OktaAuth({
  clientId: CLIENT_ID,
  issuer: ISSUER,
  pkce: false,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'email', 'profile', 'DocGen'],
});

export const ViewRoot: React.FC = observer(() => {
  const classes = useStyles();
  // prevent access to quote views if no policy has been loaded
  const viableRouteList = routeList;
  const history = useHistory();

  // If you DON'T use basename prop for <BrowserRouter>,
  // const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
  //     history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  // };

  //  If you use basename prop for <BrowserRouter>, use this implementation to fix basename duplication problem
  // eslint-disable-next-line
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    const basepath = history.createHref({});
    const originalUriWithoutBasepath = originalUri.replace(basepath, '/');
    history.replace(toRelativeUrl(originalUriWithoutBasepath, window.location.origin));
  };

  return (
    <>
      <Header />
      {!window.location.href.includes('esure.com') && <div className={classes.appVersionStyle}>{AppVersion}</div>}
      <Container className={classes.container} fixed maxWidth="xl">
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Switch>
            {viableRouteList.map((r) => (
              <SecureRoute key={`vrl${r.path}`} {...r} />
            ))}
            <Route path="/implicit/callback" component={LoginCallback} />
            <Redirect to={ROUTE_TEMPLATE_STEP.SEARCH} />
          </Switch>
        </Security>
      </Container>
    </>
  );
});
