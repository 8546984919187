import { Data } from './documentsData';

export type Order = 'asc' | 'desc';

function assertIsString(value: unknown): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error(`Expected a string, but got ${typeof value}`);
  }
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const valA = a[orderBy];
  const valB = b[orderBy];

  assertIsString(valA);
  assertIsString(valB);

  if (valB.toLowerCase() < valA.toLowerCase()) {
    return -1;
  }
  if (valB.toLowerCase() > valA.toLowerCase()) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
