import config from './config';
import axios from 'axios';
import { AccessToken } from './claims';

const docGenXapiInstance = axios.create({});

docGenXapiInstance.interceptors.request.use(
  function (conf) {
    // Do something before request is sent

    const oktaTokenStorage = localStorage.getItem('okta-token-storage');
    if (oktaTokenStorage) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { accessToken }: { accessToken: AccessToken } = JSON.parse(oktaTokenStorage);
      const token: string = accessToken.accessToken;
      conf.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return conf;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

export interface IViewingSession {
  viewingSessionId: string;
}

export const docGenXapiService = {
  beginViewing: (documentId: string, documentName: string): Promise<{ data: IViewingSession }> => {
    return docGenXapiInstance.post(
      `${config.xapi}/beginViewing?document=${documentName}&documentMetadataId=${documentId}`,
    );
  },
  beginViewingOnPrem: (documentId: string, documentName: string): Promise<{ data: IViewingSession }> => {
    return docGenXapiInstance.post(
      `${config.xapi}/beginViewingOnPrem?document=${documentName}&documentMetadataId=${documentId}`,
    );
  },
};
