import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Alert, Divider, Typography } from '@esure-cloud/react-components';
import { DocumentsGrid } from '../../component/documents/documentsGrid';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IDocument, storageManService } from '../../service/storageman';
import { useStores } from '../../service/state/store';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DocumentsUploadForm } from '../../component/documents/documentsUploadForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
  },
}));

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
export const Documents: React.FC = observer(() => {
  const { claimsStore, journeyStore } = useStores();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const query = useQuery();
  const claimNumber = query.get('claimNumber') ?? '';
  const value = journeyStore.tabIndex;

  useEffect(() => {
    if (claimNumber) {
      setLoading(true);
      storageManService
        .getSearchDocument(claimNumber)
        .then((response) => {
          claimsStore.setIncomingDocuments(response.data.results);
          //Setting documents state to render the grid
          setDocuments(response.data.results);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [claimNumber, claimsStore]);

  return (
    <div className={classes.root}>
      <TabPanel value={value} index={0}>
        <Typography variant="h4" component="h4">
          On Demand Documents Generation: Claim Number # {claimNumber}
        </Typography>
        {loading ? <LinearProgress style={{ margin: '1rem 0' }} /> : <Divider style={{ margin: '1rem 0' }} />}
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Typography variant="h4" component="h4">
          Documents view and search: Claim Number # {claimNumber}
        </Typography>
        {loading ? (
          <LinearProgress style={{ margin: '1rem 0' }} />
        ) : (
          <>
            <Divider style={{ margin: '1rem 0' }} />

            {error || documents.length === 0 ? (
              error ? (
                <Alert variant="outlined" severity="error">
                  An error has occurred, please contact your administrator
                </Alert>
              ) : (
                <Alert variant="outlined" severity="info">
                  No documents to display
                </Alert>
              )
            ) : (
              <DocumentsGrid />
            )}
          </>
        )}
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Typography variant="h4" component="h4">
          Upload documents: Claim Number # {claimNumber}
        </Typography>
        {loading ? (
          <LinearProgress style={{ margin: '1rem 0' }} />
        ) : (
          <>
            <Divider style={{ margin: '1rem 0' }} />
            <DocumentsUploadForm />
          </>
        )}
      </TabPanel>
    </div>
  );
});
