import config from './config';
import axios from 'axios';
import { IDraftRequest } from './request/request';

export interface IDamageV2 {
  damageNumber: string;
  displayValue: string;
}

export interface IClaimantV2 {
  displayValue: string;
  featureId: number;
  featureNumber: string;
  refId: string;
}

interface IRole {
  code: string;
  displayValue: string;
  parties: IParty[];
}

interface IParty {
  displayValue: string;
  email: string;
  oid: string;
  partyType: string;
  phone: string;
}

export interface ITemplateGroup {
  groupDescription: string;
  groupName: string;
  templates: ITemplate[];
}

export interface ITemplate {
  approval: boolean;
  channel: string;
  documentPartId: number;
  payload: string;
  payloadId: number;
  projectId: number;
  subject: string | null;
  templateDescription: string;
  templateId: number;
  templateName: string;
  templatePaginationId: number;
  templateSelectorId: number;
}

export interface IParameters {
  damageNumber: string;
  featureId?: number;
  featureNumber?: string;
  partyId: string;
}

export interface ICreateDraftRequest {
  parameters: IParameters;
  template: ITemplate;
}

export interface ICreateDraftGroupRequest {
  parameters: IParameters;
  templateGroup: ITemplateGroup;
}

export interface ICreateDraftResponse {
  documentPartId: number;
  payload: string;
  projectId: number;
  subject: string | null;
  templatePaginationId: number;
  templateSelectorId: number;
}

const axiosDocGenInstance = axios.create({});

export interface AccessToken {
  accessToken: string;
  claims: OktaClaims;
  expiresAt: number;
}

export interface OktaClaims {
  email: string;
  name: string;
}

axiosDocGenInstance.interceptors.request.use(
  function (conf) {
    // Do something before request is sent

    const oktaTokenStorage = localStorage.getItem('okta-token-storage');
    if (oktaTokenStorage) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { accessToken }: { accessToken: AccessToken } = JSON.parse(oktaTokenStorage);
      const token: string = accessToken.accessToken;
      conf.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return conf;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

export const claimsService = {
  createDraft: (claimId: string, body?: ICreateDraftRequest): Promise<{ data: ICreateDraftResponse }> => {
    return axiosDocGenInstance.post(`${config.apiv2}/claims/${claimId}/document/draft`, body);
  },

  getClaimantsV2: (claimId: string, damageNumber: string): Promise<{ data: IClaimantV2[] }> => {
    return axiosDocGenInstance.get(`${config.apiv2}/claims/${claimId}/${damageNumber}/claimants`);
  },

  getDamagesV2: (claimId: string): Promise<{ data: IDamageV2[] }> => {
    return axiosDocGenInstance.get(`${config.apiv2}/claims/${claimId}/damages`);
  },

  getParties: (claimId: string, damageNumber: string): Promise<{ data: IRole[] }> => {
    return axiosDocGenInstance.get(`${config.apiv2}/claims/${claimId}/damages/${damageNumber}/parties`);
  },

  getTemplate: (
    claimId: string,
    channel: string,
    role: string,
    partyType: string,
  ): Promise<{ data: ITemplateGroup[] }> => {
    return axiosDocGenInstance.get(
      ` ${config.apiv2}/claims/${claimId}/templates?channel=${channel}&role=${role}&partyType=${partyType}`,
    );
  },

  request: (claimId: string, body?: IDraftRequest): Promise<{ data: string }> => {
    return axiosDocGenInstance.post(`${config.api}/request`, body);
  },
};
