import { applySnapshot, getRoot, types } from 'mobx-state-tree';
import { IRootStore } from '.';
import { ROUTE_TEMPLATE_STEP } from '../../constant';
import { ICreateDraftResponse, IParameters, ITemplate } from '../../claims';
import { IParty } from '../../../component/search';
import { DEFAULT_PARAMETERS, DEFAULT_TEMPLATE } from '../mock/stateMock';

const ROUTE_TEMPLATE_STEP_ORDER = Object.freeze(Object.values(ROUTE_TEMPLATE_STEP));

export const DraftRequestParameters = types
  .model('DraftRequestParameters', {
    damageNumber: types.optional(types.string, ''),
    featureId: types.optional(types.number, 0),
    featureNumber: types.optional(types.string, ''),
    partyId: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setDamageNumber(val: string) {
      self.damageNumber = val;
    },
    setDraftRequestParameters(obj?: IParameters) {
      applySnapshot(self, obj);
    },
    setFeatureId(val: number) {
      self.featureId = val;
    },
    setFeatureNumber(val: string) {
      self.featureNumber = val;
    },
    setPartyId(val: string) {
      self.partyId = val;
    },
  }));

export const DraftRequestTemplate = types
  .model('DraftRequestTemplate', {
    approval: types.optional(types.boolean, false),
    channel: types.optional(types.string, ''),
    documentPartId: types.optional(types.number, 0),
    payload: types.optional(types.string, ''),
    payloadId: types.optional(types.number, 0),
    projectId: types.optional(types.number, 0),
    subject: types.maybeNull(types.string),
    templateDescription: types.optional(types.string, ''),
    templateId: types.optional(types.number, 0),
    templateName: types.optional(types.string, ''),
    templatePaginationId: types.optional(types.number, 0),
    templateSelectorId: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setApproval(val: boolean) {
      self.approval = val;
    },
    setChannel(val: string) {
      self.channel = val;
    },
    setDraftRequestTemplate(obj?: ITemplate) {
      applySnapshot(self, obj);
    },
    setPayload(val: string) {
      self.payload = val;
    },
    setPayloadId(val: number) {
      self.payloadId = val;
    },
    setProjectId(val: number) {
      self.projectId = val;
    },
    setSubject(val: string) {
      self.subject = val;
    },
    setTemplateDescription(val: string) {
      self.templateDescription = val;
    },
    setTemplateId(val: number) {
      self.templateId = val;
    },
    setTemplateName(val: string) {
      self.templateName = val;
    },
    setTemplateSelectorId(val: number) {
      self.templateSelectorId = val;
    },
  }));

export const PartyFull = types
  .model('DraftResponseFull', {
    bounced: types.optional(types.boolean, false),
    displayValue: types.optional(types.string, ''),
    email: types.maybeNull(types.string),
    oid: types.optional(types.string, ''),
    partyType: types.optional(types.string, ''),
    phone: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setBounced(val: boolean) {
      self.bounced = val;
    },
    setDisplayValue(val: string) {
      self.displayValue = val;
    },
    setEmail(val: string) {
      self.email = val;
    },
    setOid(val: string) {
      self.oid = val;
    },
    setPartyFull(obj?: IParty) {
      applySnapshot(self, obj);
    },
    setPartyType(val: string) {
      self.partyType = val;
    },
    setPhone(val: string) {
      self.phone = val;
    },
  }))
  .views((self) => ({
    getPartyFull(): IParty {
      return self;
    },
  }));

export const DraftResponseFull = types
  .model('DraftResponseFull', {
    documentPartId: types.optional(types.number, 0),
    payload: types.optional(types.string, ''),
    projectId: types.optional(types.number, 0),
    subject: types.maybeNull(types.string),
    templatePaginationId: types.optional(types.number, 0),
    templateSelectorId: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setDraftResponse(obj?: ICreateDraftResponse) {
      applySnapshot(self, obj);
    },
    setPayload(val: string) {
      self.payload = val;
    },
    setProjectId(val: number) {
      self.projectId = val;
    },
    setSubject(val: string) {
      self.subject = val;
    },
    setTemplateSelectorId(val: number) {
      self.templateSelectorId = val;
    },
  }))
  .views((self) => ({
    getDraftResponse(): ICreateDraftResponse {
      return self;
    },
  }));

export const DraftTemplateRequestFull = types
  .model('DraftTemplateRequestFull', {
    parameters: types.optional(DraftRequestParameters, DEFAULT_PARAMETERS),
    template: types.optional(DraftRequestTemplate, DEFAULT_TEMPLATE),
  })
  .actions((self) => ({
    setParameters(obj?: IParameters) {
      applySnapshot(self.parameters, obj);
    },
    setTemplate(obj?: ITemplate) {
      applySnapshot(self.template, obj);
    },
  }))
  .views((self) => ({
    getParameters(): IParameters {
      return self.parameters;
    },
    getTemplate(): ITemplate {
      return self.template;
    },
  }));

export const InterfaceDocGenSearch = types
  .model('InterfaceDocGenSearch', {
    activeStepIndex: types.optional(types.integer, 0),
    claimNumber: types.optional(types.string, ''),
    declarationsChecked: types.optional(types.boolean, false),
    selectedChannel: types.optional(types.string, ''),
    showDeclarationsError: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get activeStepPath() {
      return this.transformIndexToPath(self.activeStepIndex);
    },
    get hasNextStep() {
      return self.activeStepIndex < ROUTE_TEMPLATE_STEP_ORDER.length - 1;
    },
    get hasPrevStep() {
      return self.activeStepIndex > 0;
    },
    nearestValidStepIndex(stepIndex: number) {
      return Math.max(Math.min(stepIndex, ROUTE_TEMPLATE_STEP_ORDER.length - 1), 0);
    },
    get nextStepPath() {
      return this.transformIndexToPath(self.activeStepIndex + 1);
    },
    get prevStepPath() {
      return this.transformIndexToPath(self.activeStepIndex - 1);
    },
    get rootStore(): IRootStore {
      return getRoot(self);
    },
    transformIndexToPath(stepIndex: number) {
      return ROUTE_TEMPLATE_STEP_ORDER[this.nearestValidStepIndex(stepIndex)];
    },
    transformPathToIndex(path: string) {
      return this.nearestValidStepIndex(ROUTE_TEMPLATE_STEP_ORDER.findIndex((rqrsPath) => rqrsPath === path));
    },
  }))
  .actions((self) => ({
    setActiveStepIndex(state: number) {
      self.activeStepIndex = state;
    },
    setClaimNumber(state: string) {
      self.claimNumber = state;
    },
    setDeclarationsChecked(state: boolean) {
      self.declarationsChecked = state;
    },
    setSelectedChannel(state: string) {
      self.selectedChannel = state;
    },
    setShowDeclarationsError(state: boolean) {
      self.showDeclarationsError = state;
    },
  }));
