import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { DateTime } from 'luxon';
import { initReactI18next } from 'react-i18next';
import textRes from './locales/';

export const i18nLoader = (() => {
  const dateFormat: { [key: string]: string } = textRes.en.core.dateFormat;

  i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      debug: false,
      defaultNS: 'core',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
        format: (value: string, format) => {
          if (format) {
            if (['pricePence', 'pricePound'].includes(format)) {
              let prepValue: number = parseFloat(value);
              if (format === 'pricePence') {
                prepValue /= 100;
              }
              return new Intl.NumberFormat('en-GB', { currency: 'GBP', style: 'currency' }).format(prepValue);
            }
            if (dateFormat[format]) {
              const checkDate = DateTime.fromISO(value);
              if (checkDate.isValid) return checkDate.toFormat(dateFormat[format]);
            }
          }
          return value;
        },
      },
      lng: 'en',
      resources: textRes,
    })
    .catch((error) => {
      throw error;
    });

  return i18n;
})();
