/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { PrizmDocViewerWrapper } from '../../component/prizmDoc/PrizmDocViewerWrapper';
import { ErrorBanner } from '../../component/prizmDoc/ErrorBanner';
import { docGenXapiService } from '../../service/docgenXapi';
import config from '../../service/config';
import { PrizmDocCloudOrOnPrem } from '../../service/constant';
import { DownloadBanner } from '../../component/prizmDoc/DownloadBanner';
import { useStores } from '../../service/state/store';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const Document: React.FC = observer(() => {
  const query = useQuery();
  const documentId = query.get('id') ?? '';
  const documentName = query.get('documentName') ?? '';
  const { journeyStore } = useStores();

  //PRIZMDOC
  const [viewingSessionId, setViewingSessionId] = useState('');
  const [error, setError] = useState('');
  const [viewerControl, setViewerControl] = useState(null);

  useEffect(() => {
    if (documentId) {
      journeyStore.setCurrentDocumentMetadataId(+documentId);
      if (config.prizmDocClourOrOnPrem === PrizmDocCloudOrOnPrem.CLOUD) {
        docGenXapiService
          .beginViewing(documentId, documentName)
          .then((response) => {
            const session = response.data.viewingSessionId;
            console.log('session: ', session);
            setViewingSessionId(session);
          })
          .catch((err) => {
            setError(err);
            console.warn(err);
          });
      } else if (config.prizmDocClourOrOnPrem === PrizmDocCloudOrOnPrem.ONPREM) {
        docGenXapiService
          .beginViewingOnPrem(documentId, documentName)
          .then((response) => {
            const session = response.data.viewingSessionId;
            console.log('session: ', session);
            setViewingSessionId(session);
          })
          .catch((err) => {
            setError(err);
            console.warn(err);
          });
      }
    }
  }, [documentId]);
  useEffect(() => {
    document.title = documentName;
  }, []);
  return (
    <>
      {!error && (
        <>
          <PrizmDocViewerWrapper
            viewingSessionId={viewingSessionId} // Use the viewingSessionId as input to construct the viewer.
            style={{ height: '600px', width: '100%' }} // Set the style of the container element which will become the viewer. The width and height will affect how much space the viewer will occupy.
            onViewerReady={setViewerControl} // Once the viewer is ready, update our component state to store a reference to the viewerControl so we can programmatically interact with it (see page navigation example below).
          />


          {/*<div className="clientApiUsage">*/}
          {/*  <p>*/}
          {/*    And, once it is ready, you can programmatically interact with the viewer from a parent react component.*/}
          {/*    Here are some buttons which make viewer API calls to perform programmatic page navigation:*/}
          {/*  </p>*/}
          {/*  <button disabled={!viewerControl} onClick={() => viewerControl?.changeToPrevPage()}>*/}
          {/*    Previous Page*/}
          {/*  </button>*/}
          {/*  <button disabled={!viewerControl} onClick={() => viewerControl?.changeToNextPage()}>*/}
          {/*    Next Page*/}
          {/*  </button>*/}
          {/*</div>*/}

        </>
      )}
      {error && <ErrorBanner message={error.toString()} />}
      {error && <DownloadBanner />}
    </>
  );
});
