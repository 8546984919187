import { applySnapshot, Instance, types } from 'mobx-state-tree';
import { ICreateDraftResponse, IParameters, ITemplate, ITemplateGroup } from '../../../claims';
import {
  DEFAULT_DOCUMENT,
  DEFAULT_DRAFT_RESPONSE,
  DEFAULT_GROUP_TEMPLATE,
  DEFAULT_PARAMETERS,
  DEFAULT_TEMPLATE,
} from '../../mock/stateMock';
import { DraftRequestParameters, DraftRequestTemplate, DraftResponseFull } from '../interfaceDocGenSearch';
import { DraftRequestDocument } from '../interfaceIncomingClaim';
import { IDocument } from '../../../storageman';

export const DraftTemplateGroup = types
  .model('DraftTemplateGroup', {
    groupDescription: types.optional(types.string, ''),
    groupName: types.optional(types.string, ''),
    templates: types.array(DraftRequestTemplate),
  })
  .actions((self) => ({
    setDraftGroupTemplate(obj: ITemplateGroup) {
      applySnapshot(self, obj);
    },
  }))
  .views((self) => ({
    getDraftGroupTemplate(): ITemplateGroup {
      return self;
    },
  }));

export interface IClaim {
  currentTemplate: number;
  groupTemplate: string;
  incomingDocuments: IDocument[];
  numberOfTemplates: number;
  oktaEmail: string;
  oktaName: string;
  selectedParameters: string;
  selectedTemplate: boolean;
  selectedTemplateResponse: number;
  updateDocumentMetadata: IDocument;
}

export const ClaimsStore = types
  .model('ClaimsStore', {
    currentTemplate: types.optional(types.number, 0),
    editedTemplateResponceList: types.array(DraftResponseFull),
    groupTemplate: types.optional(DraftTemplateGroup, DEFAULT_GROUP_TEMPLATE),
    incomingDocuments: types.array(DraftRequestDocument),
    numberOfTemplates: types.optional(types.number, 0),
    oktaEmail: types.optional(types.string, ''),
    oktaFirstName: types.optional(types.string, ''),
    oktaLastName: types.optional(types.string, ''),
    oktaName: types.optional(types.string, ''),
    selectedDocumentMetadataId: types.array(types.string),
    selectedParameters: types.optional(DraftRequestParameters, DEFAULT_PARAMETERS),
    selectedTemplate: types.optional(DraftRequestTemplate, DEFAULT_TEMPLATE),
    selectedTemplateResponse: types.optional(DraftResponseFull, DEFAULT_DRAFT_RESPONSE),
    updateDocumentMetadata: types.optional(DraftRequestDocument, DEFAULT_DOCUMENT),
  })
  .actions((self) => ({
    resetEditedTemplateResponceList() {
      applySnapshot(self.editedTemplateResponceList, []);
    },
    setClaim(obj: IClaim) {
      applySnapshot(self, obj);
    },
    setCurrentTemplate(val: number) {
      self.currentTemplate = val;
    },
    setDocumentMetadataId(obj: string[]) {
      applySnapshot(self.selectedDocumentMetadataId, obj);
    },
    setEditedTemplateResponceList(obj: ICreateDraftResponse) {
      const alreadyAddedPolicyIndex = self.editedTemplateResponceList.findIndex(
        (p) => p.documentPartId === obj.documentPartId,
      );
      if (alreadyAddedPolicyIndex !== -1) {
        self.editedTemplateResponceList[alreadyAddedPolicyIndex].payload = obj.payload;
      } else {
        self.editedTemplateResponceList.push(obj);
      }
    },
    setGroupTemplate(obj: ITemplateGroup) {
      applySnapshot(self.groupTemplate, obj);
    },
    setIncomingDocuments(obj: IDocument[]) {
      applySnapshot(self.incomingDocuments, obj);
    },
    setNumberOfTemplates(val: number) {
      self.numberOfTemplates = val;
    },
    setOktaEmail(val: string) {
      self.oktaEmail = val;
    },
    setOktaFirstName(val: string) {
      self.oktaFirstName = val;
    },
    setOktaLastName(val: string) {
      self.oktaLastName = val;
    },
    setOktaName(val: string) {
      self.oktaName = val;
    },
    setSelectedParameters(obj?: IParameters) {
      applySnapshot(self.selectedParameters, obj);
    },
    setSelectedTemplate(obj?: ITemplate) {
      applySnapshot(self.selectedTemplate, obj);
    },
    setSelectedTemplateResponse(obj?: ICreateDraftResponse) {
      applySnapshot(self.selectedTemplateResponse, obj);
    },
  }))
  .views((self) => ({
    getCurrentTemplateNum(): number {
      return self.currentTemplate;
    },

    getEditedTemplateResponceList(): ICreateDraftResponse[] {
      return self.editedTemplateResponceList;
    },
    // selected group of templates for the Editor page
    getGroupTemplate(): ITemplateGroup {
      return self.groupTemplate;
    },
    getIncomingDocuments(): IDocument[] {
      return self.incomingDocuments;
    },
    getOktaEmail(): string {
      return self.oktaEmail;
    },

    getOktaName(): string {
      return self.oktaName;
    },

    getSelectedDocumentMetadataId(): string[] {
      return self.selectedDocumentMetadataId;
    },

    getSelectedParameters(): IParameters {
      return self.selectedParameters;
    },

    //as shown on editor page - request object
    getSelectedTemplate(): ITemplate {
      return self.selectedTemplate;
    },
    //as shown on editor page - response object - before save
    getSelectedTemplateResponse(): ICreateDraftResponse {
      return self.selectedTemplateResponse;
    },
    getUpdateDocumentMetadata(): IDocument {
      return self.updateDocumentMetadata;
    },
  }));

export type IClaimsStore = Instance<typeof ClaimsStore>;
export default ClaimsStore;
