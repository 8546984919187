export enum ROUTE {
  ATTACHMENT = '/attachment',
  CALLBACK = '/implicit/callback',
  DOCUMENT = '/document',
  DOCUMENTS = '/incoming',
  LANDING = '/',
  TEMPLATE = '/template',
}

export enum ROUTE_TEMPLATE_STEP {
  CONFIRMATION = '/template/confirmation',
  EDITOR = '/template/editor',
  SEARCH = '/template/search',
}

export enum ERROR_CODE {
  NOT_FOUND = 'NOT_FOUND',
}

export enum SeverityType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export enum PrizmDocCloudOrOnPrem {
  CLOUD = 'cloud',
  ONPREM = 'onprem',
}

export enum NotificationContent {
  DefaultError = 'Error has occured',
}

export const prizmDocSupportedMimeType: string[] = [
  'image/bmp',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/gif',
  'text/html',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'image/tiff',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/xml',
  'message/rfc822',
  'text/plain',
  'application/octet-stream',
  'text/htm',
  'application/vnd.ms-outlook',
  'application/vnd.adobe.xdp+xml',
  'image/jpg',
  'image/tif',
  'image/heic',
  'text/comma-separated-values',
];

//https://www.accusoft.com/products/prizmdoc-suite/prizmdoc-viewer-suppported-file-types/
export const prizmDocSupportedFileExtensions: string[] = [
  'pdf',
  'doc',
  'dot',
  'docx',
  'docm',
  'dotx',
  'dotm',
  'rtf',
  'xls',
  'xlt',
  'xlsx',
  'xlsm',
  'xltx',
  'xltm',
  'ppt',
  'pot',
  'pps',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppsx',
  'ppsm',
  'odt',
  'ott',
  'fodt',
  'ods',
  'ots',
  'fods',
  'odp',
  'otp',
  'fodp',
  'odf',
  'odg',
  'otg',
  'fodg',
  'dwg',
  'dxf',
  'dwf',
  'dgn',
  'html',
  'htm',
  'xhtml',
  'xhtm',
  'msg',
  'eml',
  'txt',
  'csv',
  'dcm',
  'dicom',
  'dcim',
  'dicm',
  'tif',
  'tiff',
  'jpg',
  'jpeg',
  'jp2',
  'jpc',
  'gif',
  'png',
  'psd',
  'psb',
  'bmp',
  'dib',
  'pct',
  'pic',
  'pict',
  'wmf',
  'emf',
  'pcx',
  'dcx',
  'ras',
  'pcd',
  'tga',
  'tpic',
  'cal',
  'cals',
  'ico',
  'cur',
  'ncr',
  'xwd',
  'sgi',
  'wbmp',
  'sct',
  'wpg',
  'xbm',
  'pbm',
  'pgm',
  'ppm',
  'img',
  'cut',
];
